<template>
  <v-app>
    <v-row class="mt-16">
      <v-col cols="12" class="mt-16 mb-n16">
        <a href="https://stafftimerapp.com/" target="_blank">
          <img class="" :src="imgSource" height="100" />
        </a>
      </v-col>
      <v-col cols="12" class="mt-n16">
        <v-icon color="success">mdi-check-circle</v-icon>
        <label class="ml-5" for>Thank you</label>
        <p>Your email has been successfully verified</p>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
export default {
  name: "email-verify",
  data() {
    return {
      check_verify: false,
      imgSource: require("@/assets/images/st_logo_new.svg"),
    };
  },
  mounted() {
    let app = this;
    app.$store.commit("custom/toggle_loader", true);
    this.$store
      .dispatch("auth/verify", this.$route.params.token)
      .then((response) => {
        this.check_verify = true;
        app.$store.commit("custom/toggle_loader", false);
      })
      .catch((error) => {
        if (error.response.data.error === true) {
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.response.data.error_msg,
            snackbarColor: "red",
          });

          app.$store.commit("custom/toggle_loader", false);
        }
      });
  },
  computed: {
    base_url() {
      return baseURL.API_URL;
    },
  },
};
</script>
<style scoped>
</style>
