<template>
  <h3 class="text-center redirect-text mt-5">Please wait ...</h3>
</template>

<script>
  export default {
    name : "company-deletion",
    mounted () {
      this.redirect ()
    },
    methods : {
      redirect () {
        this.$store.dispatch ( 'auth/verifyCompanyDeletion', this.$route.params.factor )
            .then ( response => {
              this.$root.$emit("snack_bar", {'show' : true, 'message' : response.data.error_msg, 'snackbarColor' : 'green'});
              setTimeout ( () => {
                return this.$router.push ( { name : 'login' } );
              }, 1000 );
            } )
            .catch ( error => {
              if ( error.response.data.error === true ) {
                this.$root.$emit("snack_bar", {'show' : true, 'message' : error.response.data.error_msg, 'snackbarColor' : 'red'});
              }
            } )
      }
    }
  }
</script>

<style scoped>

</style>
