<!-- System: STA
    Purpose: This compoment will display reset password
             page where user can update password
-->
<template>
  <div class="mt-16">
    <!-- Reset Password Card -->
    <v-card class="mt-16 col-12 col-sm-6 col-md-5 mx-auto my-auto" width="400">
      <div class="row">
        <div class="col-12 header">
          <div class="row">
            <div class="col-10 mx-auto">
              <a href="https://stafftimerapp.com/" target="_blank">
                <img
                  class="d-block logo-sticky w-100 logo-bold mx-auto"
                  src="../assets/images/st_logo_new.svg"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 BgContainer mt-3">
          <div class="row pt-4">
            <div class="col-12 set-header text-h5">
              <span class="subtitle-1 LeftText blue--text">Set Your</span>
              <span class="subtitle-1 RightText pl-2 red--text"
                >New Password</span
              >
            </div>
          </div>
          <div class="col-12 mt-5">
            <!-- Reset Password Form -->
            <v-form
              v-model="valid"
              @reset="onReset"
              @submit.prevent="onSubmit"
              class="mb-3"
              id="login_form"
              method="post"
              ref="loginForm"
            >
              <div
                class="alert alert-success no-display text-center form-msg"
              ></div>

              <div class="form-group pass">
                <!-- Password Text Field -->
                <v-text-field
                  class="form-control input_Feild password password-check"
                  id="passwordReset"
                  name="password"
                  label="Reset Password"
                  ref="password"
                  required
                  :rules="rules"
                  title="Reset Password"
                  v-model="form.password"
                  :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="visible ? 'text' : 'password'"
                  @click:append="visible = !visible"
                ></v-text-field>
              </div>

              <div class="form-group pass">
                <!-- Confirm Password Field -->
                <v-text-field
                  class="form-control input_Feild password confirm-password-check"
                  id="passwordConfirm"
                  name="password_confirmation"
                  label="Confirm Password"
                  required
                  :rules="rules"
                  title="Confirm Password"
                  v-model="form.repeatPassword"
                  :append-icon="visibleConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="visibleConfirm ? 'text' : 'password'"
                  @click:append="visibleConfirm = !visibleConfirm"
                ></v-text-field>
              </div>
              <div class="red--text small text-left" v-show="password_matched">
                Passwords must match
              </div>
              <div class="clearfix"></div>
              <div class="clearfix"></div>
              <!-- Reset Password Button -->
              <div class="form-group login-button-div">
                <span class="col-sm-12 p-0">
                  <v-btn
                    color="#2758f6"
                    class="btn btnContainer white--text text-capitalize text-white mt-5"
                    ref="submit"
                    type="submit"
                    dense
                    :disabled="!valid"
                    :x-large="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                    :medium="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  >
                    <v-progress-circular
                      indeterminate
                      v-if="resetLoader"
                      size="20"
                    >
                    </v-progress-circular>
                    <span>Reset Password</span>
                  </v-btn>
                </span>
              </div>
            </v-form>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      resetLoader: false,
      valid: true,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value || "").length > 5 || "Max 6 characters",
      ],
      rules_conf: [
        (value) => !!value || "Required.",
        (value) => (value || "").length > 5 || "Max 6 characters",
        (value) =>
          this.form.password === this.form.repeatPassword ||
          "Password must match",
      ],
      password_matched: false,
      visible: false,
      visibleConfirm: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_special_number: false,
      has_lowercase_uppercase: false,
      check_length_password: false,
      login_button: true,
      error_data: false,
      eyeSlashReset: false,
      eyeReset: true,
      eyeSlashConfirm: false,
      eyeConfirm: true,
      form: {
        password: "",
        repeatPassword: "",
      },
    };
  },
  created() {
    if (this.$store.state.auth.user !== null) {
      this.$store
        .dispatch("custom/user_logout")
        .then(() => {
          this.$store.commit("custom/resetState");
          this.$store.dispatch("auth/reset");
        })
        .catch(() => {
          this.$store.commit("custom/resetState");
          this.$store.dispatch("auth/reset");
        });
    }
  },
  methods: {
    /**
     * This function is resposible for sumitting
     * the reset password form (api call)
     */
    onSubmit(evt) {
      this.password_matched = false;
      evt.preventDefault();
      if (this.form.password != this.form.repeatPassword) {
        this.password_matched = true;
        return;
      }
      this.resetLoader = true;
      let formData = new FormData();
      formData.append("password", this.form.password);
      formData.append("password_confirmation", this.form.repeatPassword);
      formData.append("token", this.$route.params.token);
      this.$store
        .dispatch("auth/resetpassword", formData)
        .then((response) => {
          this.resetLoader = false;
          this.$root.$emit("snack_bar", {
            show: true,
            message: response.data.error_msg,
            snackbarColor: "green",
          });
          setTimeout(() => this.$router.push("/"), 3000);
        })
        .catch((error) => {
          this.resetLoader = false;
          if (error.response.data.errors) {
            if (error.response.data.errors.password) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.errors.password[0],
                snackbarColor: "red",
              });

              return;
            }
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          }
          if (error.response.data.error === true) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          }
        });
    },
    /**
     * This function is resposible for reset
     * the field of form
     */
    onReset(evt) {
      evt.preventDefault();
      this.form.password = "";
      this.form.confirm_password = "";
    },
  },
};
</script>

<style scoped></style>
