<!-- System: STA
    Purpose: User can Login his/her account by giving valid credentials.
-->

<template>
  <v-app>
    <v-card class="mx-auto mt-16" width="430">
      <a href="https://stafftimerapp.com/" target="_blank">
        <img :src="imgSource" width="380" height="180" class="header-img" />
      </a>
      <p class="minutes-hours-days">A minute can make a difference</p>
      <!-- Login Form -->
      <v-form
        id="login-form"
        ref="form"
        v-model="valid"
        class="mt-10 form"
        autocomplete="off"
      >
        <!-- Email Input Field -->
        <v-text-field
          id="login-email-input"
          autofocus
          class="ml-8 mr-8"
          v-model="email"
          :rules="emailRules"
          label="Email Address"
          append-icon="mdi-email"
          required
          autocomplete="off"
        ></v-text-field>

        <!-- Password Input Field -->
        <v-text-field
          id="login-password-input"
          class="ml-8 mr-8"
          v-model="password"
          :rules="required"
          label="Password"
          :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
          :type="visible ? 'text' : 'password'"
          @keyup.native.enter="login()"
          @click:append="visible = !visible"
          required
          autocomplete="off"
        ></v-text-field>
        <div
          :class="
            $vuetify.breakpoint.xs ||
            $vuetify.breakpoint.sm ||
            $vuetify.breakpoint.md
              ? 'd-flex-wrap'
              : 'd-flex'
          "
        >
          <!-- Forgot Password Page Redirect Text and Component-->
          <div
            class="ml-auto"
            :class="
              $vuetify.breakpoint.xs ||
              $vuetify.breakpoint.sm ||
              $vuetify.breakpoint.md
                ? ''
                : 'mt-4'
            "
          >
            <v-dialog v-model="forget_dialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  id="forget-password-link"
                  class="subtitle-1 float-right mr-8 text-decoration-none blue--text"
                >
                  Forget Password?
                </span>
              </template>
              <div>
                <ForgetPassword :forget_dialog="forget_dialog" />
              </div>
            </v-dialog>
          </div>
        </div>
        <!-- Login button -->
        <div class="text-center">
          <v-btn
            id="login-btn"
            color="#2758f6"
            width="80%"
            :x-large="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
            :medium="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            class="white--text text-capitalize text-white mt-5"
            :class="
              $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                ? 'subtitle-1'
                : 'title'
            "
            @click="login"
            :disabled="!valid || loginLoader"
          >
            <v-progress-circular indeterminate v-if="loginLoader" size="20">
            </v-progress-circular>
            Login</v-btn
          >
        </div>
        <!--SignUp Page Redirect Text -->
        <p class="ml-11 mt-5 mb-10">
          Don't have an account?
          <a
            id="signup-link"
            href="https://stafftimerapp.com/start-free-trial/"
            target="_blank"
            class="text-decoration-none"
          >
            Sign Up
          </a>
        </p>
      </v-form>
    </v-card>
    <!-- Snackbar For Notification -->
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-app>
</template>

<script>
import { emailRules, required } from "@/Constants/Const";
import { baseURL } from "@/assets/js/urls";
import Snackbar from "@/components/includes/Snackbar";
export default {
  components: {
    Snackbar,
    ForgetPassword: () => import("@/components/login/forgetPassword"),
  },
  data: () => ({
    forget_dialog: false,
    valid: true,
    email: "",
    password: "",
    message: "",
    visible: false,
    emailRules: emailRules,
    required: required,
    imgSource: require("@/assets/images/st_logo_new.svg"),
    loginLoader: false,
    isInit: false,
    isSignIn: false,
    error: "",
    snackbar: false,
    snackbarColor: "",
  }),
  /**
   * Check if the user is authenticated, then this code will execute
   * & user will redirect to given routes accordingly.
   */
  beforeCreate() {
    if (this.$store.state.auth.user) {
      if (this.$route.params.id) {
        return this.$router.push({
          name: "Dashboard",
          params: { id: this.$route.params.id },
        });
      } else {
        let selected_company = this.$store.state.custom.selected_company;
        if (selected_company && selected_company.id) {
          this.$router.push({
            name: "Dashboard",
            params: { id: selected_company.id },
          });
        } else this.$router.push({ name: "CompaniesListing" });
      }
    }
  },

  mounted() {
    this.$eventBus.$on("forget_dialog", (payload) => {
      this.forget_dialog = payload;
    });
  },
  computed: {
    base_url() {
      return baseURL.API_URL;
    },
  },
  methods: {
    /**
     * This function is responsible for login.
     * Email & password will be dispatch to "auth/login"
     * After this Company Listing dispatch call will be executed and
     * sets the company data into the store.
     */
    login() {
      if (this.email && this.password) {
        let formData = {
          email: this.email.toLowerCase().trim(),
          password: this.password,
        };
        this.loginLoader = true;
        this.$store
          .dispatch("auth/login", formData)
          .then(() => {
            this.$store.dispatch("custom/getAllCompaniesList").then(() => {
              if (this.$store.state.custom.companies_list.length === 1) {
                this.$store.commit(
                  "custom/set_selected_company",
                  this.$store.state.custom.companies_list[0]
                );
              }
            });

            this.$store.commit("auth/broadcast_to_other_tabs_to_reload");
            this.$store.commit("pf/keysUpdate");
            this.$store.commit("custom/USER_LOGIN", true);
            this.message = "Login Successfully";
            this.snackbarColor = "green";
            this.snackbar = true;
            this.loginLoader = false;
          })
          .catch((error) => {
            this.loginLoader = false;
            this.snackbar = true;
            this.snackbarColor = "red";
            if (error.response.status === 419) {
              document.cookie =
                "XSRF-TOKEN= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
            }
            if (
              error.response.data.error &&
              error.response.data.error_msg === "Please verify your account."
            ) {
              this.message =
                error.response.data.error_msg +
                '<br/>Not Received Verification e-mail? <a href="resend_email" class="hand d-inline-block text-primary">Click Here</a>';
              return;
            }
            if (error.response.data.error_msg) {
              this.message = error.response.data.error_msg;
            }
            if (
              error.response.data.errors &&
              error.response.data.errors.email
            ) {
              this.message =
                error.response.data.errors &&
                error.response.data.errors.email[0];
            }
            if (error.response.status === 404) {
              this.message = "Server not responding, try again in a moment";
            }
          });
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 380px) and (min-width: 280px) {
  .header-img {
    width: fit-content;
  }
}
</style>
