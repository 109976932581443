<!-- System: STA
    Purpose: This compoment will display set password
             page where user can set his company password
-->
<template>
  <v-app>
    <!-- Company Password Card -->
    <v-card class="mx-auto mt-16" width="450" max-width="500">
      <div
        :style="{
          'background-color': '#fff',
        }"
        class="w-100 login-background"
      >
        <div class="col-12">
          <a href="https://stafftimerapp.com/">
            <img
              class="d-block mx-auto logo-sticky logo-bold logo-text"
              src="../assets/images/st_logo_new.svg"
              width="380" height="180"
            />
          </a>
        </div>
        <!-- Company Password Form -->
        <v-form
          v-model="valid"
          @submit.prevent="submit"
          class="mb-3"
          id="reset_pwd"
          method="post"
          ref="loginForm"
        >
          <div class="row mt-n8">
            <div class="col-12">
              <div class="row pt-4">
                <div class="col-12 text-center">
                  <span class="LeftText">Please set up your company name and password</span>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-10 offset-1">
                  <!-- Company Name Text Field -->
                  <v-text-field
                    class="password"
                    autofocus
                    name="company_name"
                    :rules="rules_name"
                    label="Company Name"
                    required
                    type="text"
                    v-model="company_name"
                  />
                </div>
              </div>
              <div class="row pt-2 mt-n5">
                <div class="col-1"></div>
                <!-- Company Password Text Field -->
                <div class="col-10">
                  <v-text-field
                    autocomplete="current-password"
                    class="password"
                    label="Password"
                    data-vv-as="Password"
                    :rules="rules"
                    id="password"
                    name="digits_field"
                    ref="password"
                    required
                    :type="visible ? 'text' : 'password'"
                    v-model="password"
                    :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="visible = !visible"
                  >
                  </v-text-field>
                </div>
              </div>
              <!-- Sumbit Button -->
              <div class="row">
                <div class="col-10 offset-1 mt-0">
                  <v-btn
                    width="80%"
                    color="#2758f6"
                    class="btn btnContainer white--text text-capitalize text-white mt-5"
                    ref="submit"
                    type="submit"
                    :disabled="!valid"
                    :x-large="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                    :medium="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                  >
                    <v-progress-circular
                      indeterminate
                      v-if="resetLoader"
                      size="20"
                    >
                    </v-progress-circular>
                    <span class="LoginBtnText">Finish</span>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2"></div>
        </v-form>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import { required } from "@/Constants/Const";
import { baseURL } from "@/assets/js/urls";

export default {
  name: "NewCompanyPassword",
  components: {},
  data() {
    return {
      resetLoader: false,
      valid: true,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value || "").length > 5 || "Max 6 characters",
      ],
      rules_name: [
        (value) => !!value || "Required.",
        (value) => (value || "").length > 1 || "Min 2 characters",
      ],
      eyeSlash: false,
      eye: true,
      email: null,
      visible: false,
      isInit: false,
      isSignIn: false,
      password: null,
      required: required,
      confirm_password: null,
      company_name: "",
      snackbar: false,
      snackbarColor: "",
      login_errors: {
        invalidEmail: false,
        invalidPassword: false,
      },
      img_url: "",
      base64: "",
      error: "",
      password_token: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      has_special_number: false,
      has_lowercase_uppercase: false,
      check_length_password: false,
      login_button: true,
      error_data: false,
      message: "",
    };
  },

  mounted() {
    this.password_token = this.$route.params.hash_id;
  },
  computed: {
    base_url() {
      return baseURL.API_URL;
    },
  },
  methods: {
    getgtag() {
      gtag("event", "submit", {
        event_category: "onboarding",
        event_label: "Password Set",
        value: 2,
      });
    },
    /**
     * This function is resposible for sumitting
     * the company password form (api call)
     */
    submit: function () {
      this.updatePassword();
    },
    updatePassword: function () {
      this.resetLoader = true;
      this.$store.commit("custom/toggle_loader", true);
      let data = {
        password_token: this.password_token,
        password: this.password,
        name: this.company_name,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        team_size: 10,
        // g_recaptcha_response  : recaptchaToken
      };

      this.$store
        .dispatch("auth/updatePassword", data)
        .then((response) => {
          this.resetLoader = false;
          if (response.data.error === false) {
            this.$store.dispatch("auth/setCurrentUser", response.data);
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            // this.getgtag();
            this.$store.commit("custom/toggle_loader", false);
            this.$store.commit("custom/first_company_selected", true);
            // return this.$router.push ( { name : "TeamMembers", params : { id : response.data.data.company_hash }, query: { new: 'yes' } } );
            // return this.$router.push ( { name : "CompaniesListing" } );
            this.$store.dispatch("custom/getAllCompaniesList");
          }
        })
        .catch((error) => {
          console.log(error);
          this.resetLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.status === 404) {
            return alert("Server not responding, try again in a moment");
          }
          if (error.response.data.errors) {
            if (error.response.data.errors.name) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.errors.name[0],
                snackbarColor: "red",
              });
              return;
            }
            if (error.response.data.errors.password) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.errors.password[0],
                snackbarColor: "red",
              });
              return;
            }
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          }
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.response.data.error_msg,
            snackbarColor: "red",
          });
        });
    },
    createCompany() {
      let data = {
        company_name: "Default Company",
        team_size: "10", //reversed because backend is done in this way
        name: "Default Company",
      };
      let formData = new FormData();
      formData.append("name", "Default Company");
      formData.append("team_size", "10");

      this.$store
        .dispatch("custom/createCompany", formData)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.orgForm.id = response.data.company_id;
            this.$store.dispatch("custom/getAllCompaniesList");
            this.$root.$emit("snack_bar", {
              show: true,
              message: "The company has been successfully added.",
              snackbarColor: "green",
            });
            this.$store.dispatch("custom/getAllCompaniesList");
          }
          return this.$router.push({ name: "CompaniesListing" });
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.data.errors.name) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.errors.name[0],
              snackbarColor: "red",
            });
          }
          if (error.response.data.errors.team_size) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.errors.team_size[0],
              snackbarColor: "red",
            });
          }
          // return this.$router.push ( { name : "CompaniesListing" } );
        });
    },
  },
};
</script>
<style scoped></style>
