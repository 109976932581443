import axios from '../../assets/js/axios'

const state = () => ( {
    user : null,
    show_loader : false,
    showTutorialNotifications : []
} );
const mutations = {
    set_user ( store, data ) {
        store.user = data;
    },
    set_auth_token(store, data){
        store.user.auth_token = data
    },
    change_notification_status(store, data){
        store.user.notification_status = data
    },
    reset_user ( store ) {
        store.user = null;
    },
    toggle_loader ( state, bool ) {
        state.show_loader = bool;
    },
    set_page_notification ( store, data ) {
        store.showTutorialNotifications.push ( {
            page_name : data.page_name,
            is_hide : data.is_hide
        } );
    },
    set_page_notification_user ( store, data ) {
        store.showTutorialNotifications = data;
    },
    broadcast_to_other_tabs_to_reload () {
        // use local storage for messaging. Set message in local storage and clear it right away
        // This is a safe way how to communicate with other tabs while not leaving any traces
        localStorage.setItem (
            "reload-open-tabs",
            JSON.stringify ( { command : "reload" } )
        );
        localStorage.removeItem ( "reload-open-tabs" );
    }
};
const actions = {
    login ( { dispatch }, data ) {
        return axios.post ( 'auth/backoffice/login', data ).then ( response => {
            dispatch ( "setCurrentUser", response.data );
            return response;
        } );
    },
    // Updates auth_token
    update_auth_token({ commit }, data){
        return axios.post ('get-auth-token' ).then ( response => {
            commit ( "set_auth_token", response.data.auth_token );
            return response;
        } );
    },
    restpassword ( { dispatch }, data ) {
        return axios ( {
            method : "POST",
            url : "auth/backoffice/reset_password",
            data : data
        } );
    },
    reset ( { commit } ) {
        commit ( "reset_user" );
        localStorage.clear ();
        commit ( "broadcast_to_other_tabs_to_reload" );
        return Promise.resolve ();
    },
    setCurrentUser ( { commit, dispatch }, response ) {
        let unAuthorizedUsers = [
            /* DEO's */
            "ZWsA5quLheCKKCyNeB1A47X0UWoG3kvoIB8VDW4cs6yeP4FGEZ1564469889",
            "7UWZNwfXY6yNiW4h5hxhteKCeAjjO70EedVpStA92xiPgF2B2K1564469891",
            "9rVcM3CHgcWWYmOYhb5gk5gDGRYFlf0O8Eps98f0YobGaWmcyW1564469893",
            "uA1HnI1nChCPKr3MO4t3wVxgLK8tZDIoMJeJ9HPIh4LG7Hb3oE1564469895",
            "Q3ccPiEX2fb6ETQoUcEqJWZw8FcHCKVXXXNgIrUZUGlMQalrGE1564469897",
            "Qe0kDmIGwVxGWdY44g0zaknPsaq0x9TpNokBxuNeylj7pHOPel1564469899",
            "mofPQs2OwfZRi4wZBEabIgdAbGkoHobK9im24dNzeBTQhyEhYP1564469902",
            "BkcSG5HCXZ4DQGp6cw6joRlWms3RuwtRfkfz6fUuXgGr6llWGw1564469904",
            "Jzu98Ha6DnmHWc1RxAHl60aXMxueqbZjRlIYKYbDPY3Eip74n91564469906",
            "x3t204eeNbYzHyiIR0QICtAenm0l5pv5BLUv3xDWUJptEjAu7h1564469907",
            "rR87JRjroq2s09ghAvD1LmGQGNiAtlivzOmzwoQSYOsHEEyLuU1564470180",
            "cjpWmvDnPb1PVZvQOzae5Ijhn1euBqocrXWUTn3VTbYA6iIXD81564470183",
            "puJUP91sQfk4Dm4XstbH8K8nfUhofdUik3OCZdagc9xDbrxWxY1564470186",
            "Fq5pQtgDCrylPC8J5g3iHI95z6hnmSboV7iCEk9sO73NP9e11k1564470189",
            "hTpnsbw04SOLvsBI9XHoH737ehKDQ4lJPXCpy39zNhl6Kvxg2t1564470191",
            "d71HsKQ0Kg2S2On8QQQagF2MQMnmoRYaJJkbntRlPjdoKkEW1H1564470193",
            "ymcCggkUapRLNuZPXKViZZuXv0pHklDGdCfeX42LNDJN6eYG4I1564470195",
            "SvzMD9i2C4j9WnmryzPlh825th5AXmZbT7cuwEjb0rDfF9BuDj1564470197",
            "2cVRvT1R8nugfX10VT5F79qZ4NVc8VE1yXEtkWKLLCWrLDqE951564470198",
            "mHK4yNpsY2EGRGxVpAELaG5OHviMy07bPivVis3uHx3kjdRLhn1564470200",
            "xmRrhjghakX0HbhZXYwdBsz9YosrmzgMRDpokefmmq9V1rjxgD1564484409",
            "xqrQCfTONCB5C7jFOGqtjQItRlnRBtDNjikudqGkjh3LxUrgwx1564485506",
            "17cQPlW7NYSLStbaLMvgYWVna77FZaZ4zZrvFAxPquMmjnW2Da1564486783",
            "RAqtqL0cRw0xeE9bktXXhFY0wRvSZQDl4sWnHB5jhlEM4YatFM1565355078"
        ];
        if ( unAuthorizedUsers.includes ( response.data.auth_user.id ) )
            dispatch ( "reset" );
        else {
            commit ( "set_user", response.data.auth_user );
            commit ( "set_page_notification_user", response.data.show_notification );
        }
    },
    googleLogin ( { dispatch }, data ) {
        return axios.post ( "app/google-signup", data ).then ( response => {
            dispatch ( "setCurrentUser", response );
        } );
    },
    verify ( NULL, data ) {
        return axios.get ( "verify/" + data );
    },
    verifyCompanyDeletion ( NULL, data ) {
        return axios.get ( "company/delete/twofactor/" + data );
    },
    setPassword ( NULL, data ) {
        return axios.post ( "invitation/accept", data );
    },
    resetpassword ( NULL, data ) {
        return axios.post ( "auth/password", data );
    },
    updatePassword ( NULL, data ) {
        return axios.post ( "update-password", data );
    },
    app_login ( NULL, data ) {
        return axios.post ( "app/backoffice/login", data );
    },
    confirm_email ( NULL, data ) {
        return axios.post ( "auth/confirm-email", data );
    },
    deactivateUser () {
        return axios.post ( "auth/deactivate-user" );
    },
    project_invitation_link ( NULL, data ) {
        return axios.post ( "project/invitation/accept", data );
    },
    join_invitation_link ( NULL, data ) {
        return axios.post ( "invitation-link-join", data );
    },
    checkEmail(NULL, data){
        return axios.post("user-account", data);
    },
    getCompanyName(NULL, data){
        return axios.post("company-name", data);
    },
    isUserAlreadyVerified ( { commit }, data ) {
        commit ( "toggle_loader", true );
        return axios.post ( "is-already-verified", data );
    },
    pageNotificationStore ( { commit }, data ) {
        commit ( "set_page_notification", data );
        return axios.post ( "page-notification-store", data );
    },
    get_sanctum_cookies(){
        return axios.get ( "api/csrf-cookie");
    }
};

export default {
    namespaced : true,
    state,
    actions,
    mutations
};
