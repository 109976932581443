<!-- System: STA
    Purpose: Invitation form appears when a new user is invited
            by a link.
-->

<template>
  <v-app>
    <v-card class="mx-auto mt-16" width="450" v-if="!email_test">
      <a href="https://stafftimerapp.com/" target="_blank">
        <img :src="imgSource" width="400" height="180" />
      </a>
      <p class="minutes-hours-days">A minute can make a difference</p>
      <!-- Invite Link Form -->
      <v-form
        @submit.prevent="checkEmailExist"
        v-model="valid"
        id="reset_pwd"
        method="post"
        ref="loginForm"
        class="mt-10 form"
        autocomplete="off"
      >
        <v-row class="d-block text-caption">
          <v-col cols="12" v-if="company_name">
            <span>
              <span
                ><b>{{ company_name }} </b></span
              >
              <b
                >has invited you to join their team,<br />
                please enter your email to get started</b
              ></span
            >
          </v-col>
        </v-row>
        <!-- Email Input Field -->
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="login-email-input"
              autofocus
              class="ml-8 mr-8"
              v-model="email"
              :rules="emailRules"
              label="Email Address"
              append-icon="mdi-email"
              required
              autocomplete="off"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Next button -->
        <v-row>
          <v-col cols="12">
            <div class="text-center mb-5">
              <v-btn
                color="#2758f6"
                width="80%"
                :x-large="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                :medium="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                class="white--text text-capitalize text-white mt-5"
                :class="
                  $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                    ? 'subtitle-1'
                    : 'title'
                "
                ref="submit"
                type="submit"
                :disabled="!valid"
              >
                Next</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <!-- Render if the user email is not exists -->
    <EmailExist v-if="email_test" :user_email="email" />
  </v-app>
</template>

<script>
import { emailRules, required } from "@/Constants/Const";
import Cookie from "js-cookie";
import { mapState } from "vuex";
export default {
  name: "InviteLink",
  props: ["user_email"],
  ...mapState("custom", ["selected_company", "companies_list"]),
  data() {
    return {
      valid: true,
      company_name: "",
      imgSource: require("@/assets/images/st_logo_new.svg"),
      email: null,
      name: null,
      emailRules: emailRules,
      required: required,
      invite_token: "",
      check_user: false,
      email_test: false,
    };
  },
  components: {
    EmailExist: () => import("@/components/invitelink/email-exist"),
  },
  mounted() {
    document.cookie = "XSRF-TOKEN= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    Cookie.remove("XSRF-TOKEN", {
      path: "/",
      domain: ".stafftimerapp.com",
      sameSite: "lax",
    });
    Cookie.remove("stafftimerapp_session", {
      path: "/",
      domain: ".stafftimerapp.com",
      sameSite: "lax",
    });
    this.invite_token = this.$route.params.invite_token;
    this.getName();
  },
  methods: {
    /**
     * This function is responsible for get the company name.
     */
    getName() {
      this.$store.commit("custom/toggle_loader", true);
      let data = {
        invite_token: this.invite_token,
      };
      this.$store
        .dispatch("auth/getCompanyName", data)
        .then((response) => {
          this.company_name = response.data.ccompany_name;
          this.$store.commit("custom/toggle_loader", false);
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.data.error_msg,
            snackbarColor: "red",
          });
        });
    },
    /**
     * This function is responsible for checking the user
     * email / account exists or not.
     * If user email / account exists then it will route
     * towards login page else EmailExist Component will be render.
     */
    checkEmailExist() {
      let formData = {
        email: this.email.toLowerCase().trim(),
        invite_token: this.invite_token,
      };
      this.$store
        .dispatch("auth/checkEmail", formData)
        .then((response) => {
          this.check_user = response.data.user_type;
          if (this.check_user === true) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: "Your account exists !please login",
              snackbarColor: "green",
            });
            if (this.$store.state.auth.user) {
              if (this.$store.state.auth.user.email !== this.email) {
                this.$store
                  .dispatch("custom/user_logout")
                  .then(() => {
                    this.$store.commit("custom/resetState");
                    this.$store.dispatch("auth/reset").then(() => {
                      location.reload(true);
                    });
                  })
                  .catch(() => {});
              }
            }
            this.$router.push({ name: "login" });
          } else if (this.check_user === false) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.email_test = true;
          }
        })
        .catch((error) => {
          if ((error.response.data.error = true)) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          }
        });
    },
  },
};
</script>
<style scoped></style>
