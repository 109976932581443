import io from 'socket.io-client'
import { baseURL } from "./urls";
const socket = io(baseURL.NODE_URL, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity
});

export default socket
