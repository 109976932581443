import axios from "../../assets/js/axios";
import moment from "moment";
import router from "../../router";
import { min_to_hour } from "../../assets/js/utils";
import _ from "lodash";
const config = {
    headers: {
        "content-type": "multipart/form-data",
    },
};
const initialState = () => ({
    real_time_user: "",
    first_time_user: false,
    backoffice_permission: {
        companyName: "",
        companyPermission: ""
    },
    show_company_message: false,
    first_company: false,
    check_company: false,
    workDairyLoader: false,
    user_login: false,
    cookie_data: [],
    groupMontring: [],
    activeLoader: true,
    selected_company: {
        id: null,
        name: null,
        user_status: false,
        additional_features_allowed : "",
        logo: null,
        monthly_amount: null,
        yearly_amount: null,
        stripe_plan: null,
        stripe_product: null,
        last_working_date: null,
    },
    members_filter_key: {
        project: "all",
        department: "all",
        search_by_name: null,
        search_by_lead: null,
    },
    companies_list: [],
    all_companies_list: {
        active: [],
        archived: [],
    },
    widget_data: {
        total_minutes: {
            hrs: 0,
            mins: 0,
        },
    },
    dashboard_data: {
        projects: [],
        members: [],
        last_working_days: null,
    },
    dashboard_chart_data: {
        labels: [],
        active: [],
        inActive: [],
        tooltips: [],
    },
    projects_list: {
        active: [],
        archived: [],
    },
    team_leads_members: [],
    employer_employees: [],
    single_project: [],
    categories: [],
    team_members: [],
    active_team_members_list: [],
    archived_team_members_list: [],
    team_members_projects_list: [],
    lead_team_members: [],
    inviteMembersFormData: {},
    get_all_companies: [],
    screenshots: [],
    company_payments_cards: [],
    billing_info: [],
    loader: false,
    invoices: [],
    paypal_invoices: [],
    edit_company_data: [],
    isConnected: false,
    all_store_socket_employee: [],
    training_data: {
        similar_ids: [],
        not_similar_ids: [],
        matched_ids: [],
    },
    diary_member_id: "",
    diary_project: "",
    diary_task: "",
    report_member_id: "",
    work_diary_date_range: "",
    work_diary_date: "",
    show_loader: false,
    show_progress_loader: false,
    active_windows_reports: [],
    reports: [],
    reportsByMembers: [],
    reportsByProjects: [],
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    selected_project: "all",
    company_subscription_status: true,
    payment_gateway: null,
    p_subscription_id: null,
    paypal_plan_id: null,
    check_trial_period_status: true,
    stripe_plan: null,
    stripe_product: null,
    tasks: [],
    tags: [],
    departments: [],
    getdepartmentslist: [],
    currencies: [],
    routerKey: false,
    IS_ANY_OFF_IP: false,
    tasks_current_tab: "ownedTasks",
    search_image: false,
    filter_active_team_members_list: [],
    task_members: [],
    get_manual_time_list: [],
    get_team_members_list: [],
    team_member_with_project: [],
    company_department_list: "",
    last_working_date: "",
    project_task: [],
    work_diary_team_members: []
});
const mutations = {
    set_active_loader(state, data) {
        state.activeLoader = data
    },
    set_backoffice_permission(state, data) {
        state.backoffice_permission.companyName = data.companyName;
        state.backoffice_permission.companyPermission = data.backofficePermission;
    },
    set_member_filter_key(state, data) {
        state.members_filter_key.project = data.project;
        state.members_filter_key.department = data.department;
        state.members_filter_key.search_by_name = data.search_by_name;
        state.members_filter_key.search_by_lead = data.search_by_lead;
    },
    set_permissions(state, data) {
        state.selected_company.permission = data;
    },
    set_company_department_list(state, data) {
        state.company_department_list = data;
    },
    set_work_diary_team_members(state, data) {
        state.work_diary_team_members = data;
    },
    set_task_members(state, data) {
        state.task_members = data;
    },
    set_team_leads_members(state, data) {
        state.team_leads_members = data
    },
    set_project_task_list(state, data) {
        state.project_task = data
    },
    set_last_working_day(state, data) {
        state.last_working_date = data
    },
    set_last_working_days(state, data) {
        state.dashboard_data.last_working_days = data;
        state.last_working_date = data
    },
    set_team_members_list(state, data) {
        state.get_team_members_list = data;
    },
    set_team_member_with_project(state, data) {
        state.team_member_with_project = data
    },
    USER_LOGIN(state, data) {
        state.user_login = data;
    },

    group_montring(store, data) {
        store.groupMontring = data;
    },

    set_manual_time_list(store, data) {
        store.get_manual_time_list = data;
    },
    work_dairy_loader(state, data) {
        state.workDairyLoader = data;
    },
    set_cookie_data(store, data) {
        store.cookie_data = data;
    },
    first_time(store, data) {
        store.first_time_user = data;
    },
    set_real_time_user(store, data) {
        store.real_time_user = data;
    },
    first_company_selected(store, data) {
        store.first_company = data;
    },


    set_check_company(store, data) {
        store.check_company = data;
    },
    set_show_company_message(store, data) {
        store.show_company_message = data;
    },
    image_data(state, bool) {
        state.search_image = bool;
    },

    set_active_windows_reports(store, data) {
        store.active_windows_reports = data;
    },
    set_companies_list(store, data) {
        store.companies_list = data;
    },
    set_all_companies_list(store, data) {
        store.all_companies_list = data;
    },
    set_widgets_data(store, data) {
        store.widget_data = data;
    },
    set_widgets_data_minutes(store, data) {
        store.widget_data.total_minutes = data;
    },
    set_dashboard_data_projects(store, data) {
        store.dashboard_data.projects = data;
    },
    set_dashboard_data_members(store, data) {
        store.dashboard_data.members = data;
    },
    set_dashboard_chart_data(store, data) {
        store.dashboard_chart_data = data;
    },
    set_projects_list(store, data) {
        store.projects_list = data;
    },
    set_employer_employees(store, data) {
        store.employer_employees = data;
    },
    set_single_project(store, data) {
        store.single_project = data;
    },
    push_categories(store, data) {
        store.categories.push(data);
    },
    set_selected_company(state, data) {
        if (data) {
            if (data.user_status !== "employee" || data.has_team)
                this._vm.$socket.emit("backofficeLogin", {
                    user_id: this.state.auth.user.id,
                    name: this.state.auth.user.name,
                    company_id: data.id,
                    user_status: data.user_status,
                    // auth_token: this.state.auth.user.auth_token
                });

            // let custom_company_id = window.location.href + this._vm.$route.params.id
            // localStorage.setItem(custom_company_id, JSON.stringify(data));

            state.selected_company.id = data.id;
            state.selected_company.last_working_date = data.last_working_date;
            state.selected_company.name = data.name;
            state.selected_company.user_status = data.user_status;
            state.selected_company.logo = data.logo;
            state.selected_company.has_team = data.has_team;
            state.selected_company.is_real_time_allowed = data.is_real_time_allowed;
            state.selected_company.additional_features_allowed = data.additional_features_allowed;
            state.selected_company.monthly_amount = data.monthly_amount;
            state.selected_company.yearly_amount = data.yearly_amount;
            state.selected_company.show_billing = data.show_billing;
            state.selected_company.stripe_plan = data.stripe_plan;
            state.selected_company.stripe_product = data.stripe_product;
            state.selected_company.view_backoffice = data.view_backoffice;
            state.company_subscription_status = data.company_subscription_status;
            state.payment_gateway = data.payment_gateway;
            state.p_subscription_id = data.p_subscription_id;
            state.paypal_plan_id = data.paypal_plan_id;

            state.check_trial_period_status = data.check_trial_period_status;
            state.onGracePeriod = data.onGracePeriod;
            state.subscription_quantity = data.subscription_quantity;
            state.selected_company.permission = data.permission;
            state.selected_company.paypal_approve_link_update = data.paypal_approve_link_update
        } else {
            state.selected_company.id = null;
            state.selected_company.name = null;
            state.selected_company.user_status = false;
            state.selected_company.logo = null;
            state.selected_company.has_team = null;
            state.selected_company.is_real_time_allowed = false;

            state.selected_company.monthly_amount = null;
            state.selected_company.yearly_amount = null;
            state.selected_company.show_billing = null;
            state.selected_company.stripe_plan = null;
            state.selected_company.stripe_product = null;
            state.selected_company.view_backoffice = false;
            state.company_subscription_status = false;
            state.check_trial_period_status = false;
            state.onGracePeriod = false;
            state.subscription_quantity = null;
            state.selected_company.paypal_approve_link_update = data.paypal_approve_link_update
        }
    },
    set_team_members(store, data) {
        store.team_members = data;
    },
    set_p_subscription_id(store, data) {
        store.p_subscription_id = data;
    },
    set_paypal_plan_id(store, data) {
        store.paypal_plan_id = data;
    },
    set_payment_gateway(store, data) {
        store.payment_gateway = data;
    },
    set_load(store, data) {
        store.company_subscription_status = data;
    },
    set_filter_active_team_member_list(store, data) {
        store.filter_active_team_members_list = data;
    },
    set_active_team_members_list(store, data) {
        store.active_team_members_list = data;
    },
    set_archived_team_members_list(store, data) {
        store.archived_team_members_list = data;
    },
    set_team_members_project_list(store, data) {
        store.team_members_projects_list = data;
    },
    set_lead_team_members(store, data) {
        store.lead_team_members = data;
    },
    set_member_interval(store, data) {
        store.team_members.find((team_member) => {
            if (team_member.id === data.member_id)
                team_member.interval = data.interval;
        });
    },
    remove_from_not_my_project(store, data) {
        store.team_members.find((team_member) => {
            if (team_member.id === data.member_id) {
                delete team_member.not_my_projects[data.project_id];
            }
        });
    },
    remove_project(store, index) {
        store.projects_list.splice(index, 1);
    },
    inviteMembersFormData(store, data) {
        store.inviteMembersFormData = data;
    },
    pushIntoInviteMembersFormData(store, data) {
        if (
            store.inviteMembersFormData.designations &&
            store.inviteMembersFormData.emails
        ) {
            data.designations.forEach((designation) => {
                store.inviteMembersFormData.designations.push(designation);
            });
            data.emails.forEach((email) => {
                store.inviteMembersFormData.emails.push(email);
            });
        } else {
            store.inviteMembersFormData = data;
        }
    },
    splice_inviteMembersFormData(store, data) {
        store.inviteMembersFormData.emails.splice(data, 1);
        store.inviteMembersFormData.designations.splice(data, 1);
    },
    update_project_name(store, data) {
        store.single_project.project_name = data;
    },
    update_start_date(store, data) {
        store.single_project.start_date = data;
    },
    push_member(store, data) {
        store.single_project.members.push(data);
    },
    splice_member(store, data) {
        store.single_project.members.splice(data, 1);
    },
    splice_company(store, data) {
        store.all_companies_list.active.splice(data, 1);
    },
    splice_archived_company(store, data) {
        store.all_companies_list.archived.forEach((element, index) => {
            if (element.id === data) {
                store.all_companies_list.archived.splice(index, 1);
            }
        });
    },
    splice_project(store, data) {
        let index = store.projects_list.active.findIndex(
            (value) => value.id === data
        );
        store.projects_list.active.splice(index, 1);
    },
    splice_archived_project(store, data) {
        store.projects_list.archived.forEach((element, index) => {
            if (element.id === data) {
                store.projects_list.archived.splice(index, 1);
            }
        });
    },
    push_company(store, data) {
        store.all_companies_list.active.push(data);
    },
    push_archive_company(store, data) {
        store.all_companies_list.archived.push(data);
    },
    push_project(store, data) {
        store.projects_list.active.push(data);
    },
    push_archive_project(store, data) {
        store.projects_list.archived.push(data);
    },
    set_screenshots(store, data) {
        store.screenshots = data;
    },
    set_categories(store, data) {
        store.categories = data;
    },
    set_company_payments_cards(store, data) {
        store.company_payments_cards = data;
    },
    set_billing_info(store, data) {
        store.billing_info = data;
    },
    set_loader(store, data) {
        store.show_loader = data;
    },
    set_payment_history(store, data) {
        store.invoices = data;
    },
    set_paypal_payment_history(store, data) {
        store.paypal_invoices = data;
    },
    edit_company_data(store, company) {
        store.edit_company_data = company;
    },
    set_reports(store, reports) {
        store.reports = reports;
    },
    set_reportsByMembers(store, reportsByMembers) {
        store.reportsByMembers = reportsByMembers;
    },
    set_reportsByProjects(store, reportsByProjects) {
        store.reportsByProjects = reportsByProjects;
    },
    resetState(state) {
        const initial = initialState();
        Object.keys(initial).forEach((key) => {
            state[key] = initial[key];
        });
    },

    delete_screenshot(state, data) {
        state.screenshots.forEach((record) => {
            record.forEach((array_obj) => {
                array_obj.data.forEach((element) => {
                    element.images.forEach((value) => {
                        Object.keys(value).forEach((obj_result) => {
                            if (data === value[obj_result]["id"]) {
                                delete value[obj_result];
                            }
                        });
                    });
                });
            });
        });
    },
    updateRouterKey(state) {
        state.routerKey = !state.routerKey;
    },
    store_socket_employee(state, data) {
        state.all_store_socket_employee = data;
    },
    SOCKET_CONNECT(state) {
        state.isConnected = true;
    },

    SOCKET_DISCONNECT(state) {
        state.isConnected = false;
    },

    toggle_loader(state, bool) {
        state.show_loader = bool;
    },

    toggle_progress_loader(state, bool) {
        state.show_progress_loader = bool;
    },
    set_diary_member_id(store, data) {
        store.diary_member_id = data;
    },
    set_diary_project(store, data) {
        store.diary_project = data;
    },
    set_diary_task(store, data) {
        store.diary_task = data;
    },
    set_work_diary_date_range(store, data) {
        store.work_diary_date_range = data;
    },
    set_work_diary_date(store, data) {
        store.work_diary_date = data;
    },
    set_report_member_id(store, data) {
        store.report_member_id = data;
    },
    set_start_date(store, data) {
        store.start_date = data;
    },
    set_end_date(store, data) {
        store.end_date = data;
    },
    set_selected_project(store, data) {
        store.selected_project = data;
    },
    set_departments(store, data) {
        store.departments = data;
    },
    set_currencies(store, data) {
        store.currencies = data;
    },
    set_user_tags(state, data) {
        let memberIndex = state.active_team_members_list.findIndex(
            (m) => m.id === data.member.id
        );
        state.active_team_members_list[memberIndex].tags = data.assignedTags;
    },
};
const actions = {
    redirectToCompaniesListing: _.debounce(
        function ({ state, commit }) {
            if (!state.all_companies_list)
                this.$vue.$notify("Please!", "Add a company first", "info");
            return router.push({ name: "CompaniesListing" }).catch((error) => { });
        },
        1000,
        { leading: false, trailing: true }
    ),

    getManaulList({ dispatch, commit }, data) {
        return axios.post("backoffice/manual-time-report", data);
    },
    getTeamLeadMembers({ dispatch, commit }, data) {
        return axios.post("get-team-lead-members", data);
    },
    edit_single_user_profile: _.debounce(
        function ({ dispatch, commit }, data) {
            commit("toggle_loader", true);
            return axios.post("users-setting-profile", data);
        },
        1000,
        { leading: true, trailing: false }
    ),
    getDeaprtmentList: _.debounce(
        function ({ dispatch, commit }, data) {
            return axios.post("/company-department-list", data);
        },
        1000,
        { leading: true, trailing: false }
    ),
    edit_customer_profile({ dispatch, commit }, data) {
        return axios.post("/edit-customer-profile", data);
    },
    search_add_department({ dispatch, commit }, data) {
        return axios.post("/add-department-new", data);
    },
    add_company_department({ dispatch, commit }, data) {
        return axios.post("/add-company-department", data);
    },
    getAllCompaniesList: _.debounce(
        function ({ commit, dispatch, state }) {
            return axios
                .get("company-list")
                .then((response) => {
                    if (response.data.error === false) {
                        let company_list = {
                            active: [],
                            archived: [],
                        };
                        let sel_company = null;
                        if (response.data.companies.length === 1) {
                            let singleCompany = response.data.companies[0];
                            if (state.first_time_user === true) {
                                router.push({
                                    name: "DownloadApp",
                                    params: {
                                        id: response.data.companies[0].id,
                                    },
                                    query: { first: "first_time" },
                                });
                                commit("set_check_company", true);
                                commit("set_cookie_data", null);
                            } else if (state.first_company === true) {
                                router.push({
                                    name: "TeamMembers",
                                    params: {
                                        id: response.data.companies[0].id,
                                    },
                                    query: { new: "yes" },
                                });
                                commit("set_check_company", true);
                            } else {
                                if (state.first_company === true) {
                                    router.push({
                                        name: "TeamMembers",
                                        params: {
                                            id: response.data.companies[0].id,
                                        },
                                        query: { new: "yes" },
                                    });
                                    commit("set_check_company", true);
                                } else if (
                                    state.check_company === false &&
                                    state.first_company === false &&
                                    state.first_time_user === false
                                ) {
                                    if (singleCompany.user_status.trim() === "employee") {
                                        router.push({
                                            name: "Projects",
                                            params: {
                                                id: singleCompany.id,
                                            },
                                        });
                                    } else {
                                        router.push({
                                            name: "TeamMembers",
                                            params: {
                                                id: singleCompany.id,
                                            },
                                        });
                                    }
                                }
                            }
                            // if (state.check_company === false && state.first_company === false && state.first_time_user === false) {
                            //   router.push({
                            //     name: "Dashboard",
                            //     params: {
                            //       id: response.data.companies[0].id,
                            //     },
                            //   });
                            //   commit("set_check_company", true);
                            // }
                            // let singleCompany = response.data.companies[0];
                            // if (singleCompany.user_status.trim() === 'employee') {
                            //   router.push({
                            //       name: "Projects",
                            //       params: {
                            //           id: singleCompany.id,
                            //       },
                            //   });
                            // }else{
                            //   router.push({
                            //       name: "TeamMembers",
                            //       params: {
                            //           id: singleCompany.id,
                            //       },
                            //   });
                            // }
                        }
                        commit("first_company_selected", false);
                        commit("first_time", false);
                        commit("set_check_company", true);
                        response.data.companies.forEach((element) => {
                            if (element.company_status === "active") {
                                company_list.active.push(element);
                                if (element.id === state.selected_company.id) {
                                    sel_company = {
                                        id: element.id,
                                        logo: element.logo,
                                        name: element.name,
                                        team_size: element.team_size,
                                        user_status: element.user_status,
                                        payment_gateway: element.payment_gateway,
                                        p_subscription_id: element.p_subscription_id,
                                        paypal_plan_id: element.paypal_plan_id,
                                        company_subscription_status:
                                            element.company_subscription_status,
                                        onGracePeriod: element.onGracePeriod,
                                        check_trial_period_status:
                                            element.check_trial_period_status,
                                        has_team: element.has_team,
                                        is_real_time_allowed: element.is_real_time_allowed,
                                        stripe_plan: element.stripe_plan,
                                        stripe_product: element.stripe_product,
                                        monthly_amount: element.monthly_amount,
                                        yearly_amount: element.yearly_amount,
                                        view_backoffice: element.view_backoffice,
                                        subscription_quantity: element.subscription_quantity,
                                        active_status: element.active_status,
                                        permission: element.permission,
                                        subscription_canceled: element.subscription_canceled,
                                        paypal_approve_link_update: element.paypal_approve_link_update,
                                    };
                                    commit("set_selected_company", sel_company);
                                    if (
                                        state.selected_company.user_status !==
                                        sel_company.user_status
                                    ) {
                                        commit("set_selected_company", sel_company);
                                        commit("set_team_members", []);
                                        commit("set_team_members_list", []);
                                        dispatch("getProjects", { company_id: sel_company.id });
                                        router.push({
                                            name: "Projects",
                                            params: { id: sel_company.id },
                                        });
                                    } else {
                                        commit("set_selected_company", sel_company);
                                        let lastCompany = this.state.auth.user.last_company;
                                        if (lastCompany != null) {
                                            let select_company = response.data.companies.find(
                                                (d) => d.id === lastCompany
                                            );
                                            if (select_company.user_status.trim() != "employee") {
                                                commit("set_permissions", select_company.permission);
                                            }
                                            if (select_company.user_status.trim() == "employer") {
                                                let permission = {
                                                    admin_permission: true,
                                                    billing: true,
                                                    dashboard: true,
                                                    project: true,
                                                    reports: true,
                                                    task: true,
                                                    team_member: true,
                                                    work_diary: true
                                                }
                                                commit("set_permissions", permission);
                                            }
                                        }
                                    }
                                }
                            } else company_list.archived.push(element);
                        });

                        commit("set_companies_list", company_list.active);

                        if (!state.selected_company.id) {
                            if (this.state.auth.user.last_company) {
                                for (let i = 0; i < company_list.active.length; i++) {
                                    if (company_list.active[i].id == this.state.auth.user.last_company) {
                                        commit(
                                            "set_selected_company",
                                            (sel_company = company_list.active[i])
                                        );
                                    }
                                }
                            }
                            else {
                                commit(
                                    "set_selected_company",
                                    (sel_company = company_list.active[0] || null)
                                );
                            }
                        }

                        if (!sel_company) {
                            if (
                                !["AccountSettingsRoot", "CompaniesListing"].includes(
                                    this.$vue.$route.name
                                ) &&
                                !company_list.active.length &&
                                !company_list.archived.length
                            ) {
                                router.push({ name: "AddCompany" });
                            }
                            if (this.$vue.$route.name !== "AddCompany")
                                router.push({ name: "CompaniesListing" }).catch(() => { });
                        }
                        if (company_list.active.length !== state.companies_list.length) {
                            dispatch("getAllCompaniesList");
                        }
                        commit("set_all_companies_list", company_list);
                        let lastCompany = this.state.auth.user.last_company;
                        if (state.user_login) {
                            if (lastCompany) {
                                let exists = response.data.companies.find(
                                    (d) => d.id === lastCompany
                                );
                                if (exists) {
                                    if (exists.user_status.trim() === "employee" && exists.has_team == 0) {
                                        router.push({
                                            name: "Projects",
                                            params: {
                                                id: lastCompany,
                                            },
                                        });
                                    } else {
                                        commit("set_permissions", exists.permission);
                                        router.push({
                                            name: "TeamMembers",
                                            params: {
                                                id: lastCompany,
                                            },
                                        });
                                    }
                                }
                            } else {
                                router.push({
                                    name: "CompaniesListing",
                                });
                            }
                        }
                        commit("USER_LOGIN", false);
                        // if (state.cookie_data.length !== 0){
                        //   let exists = response.data.companies.find(d => d.id === state.cookie_data.id);
                        //   if(exists){
                        //     if(exists.user_status.trim() === 'employee'){
                        //       router.push({
                        //         name: "Projects",
                        //         params: {
                        //           id: state.cookie_data.id,
                        //         },
                        //       });
                        //     }else{
                        //       router.push({
                        //         name: "TeamMembers",
                        //         params: {
                        //           id: state.cookie_data.id,
                        //         },
                        //       });
                        //     }
                        //     commit("set_cookie_data", null);
                        //   }
                        // }
                    }
                    return true;
                })
                .catch((error) => {
                    setTimeout(() => {
                        dispatch("getAllCompaniesList");
                    }, 10000);
                    if (error.response) {
                        if (error.response.data.message === "Unauthenticated.") {
                            commit("resetState");
                            dispatch("pf/resetKeys");
                            dispatch("auth/reset", null, {
                                root: true,
                            }).then(() => {
                                window.location.href = "/login";
                                //return router.push('/login')
                            });
                        }
                    }
                });
        },
        1000,
        { leading: true, trailing: false }
    ),
    getDashboardData: _.debounce(
        function ({ commit }, data) {
            return axios
                .post("dashboard", data)
                .then((response) => {
                    commit("toggle_loader", false);
                    commit("set_widgets_data", response.data.data.widgets);
                    commit(
                        "set_widgets_data_minutes",
                        min_to_hour(response.data.data.widgets.total_minutes)
                    );
                    commit("set_last_working_days", response.data.data.last_working_days);
                    if (response.data.data.projects !== undefined) {
                        let projects = Object.assign([], response.data.data.projects);
                        projects.forEach((project) => {
                            project.all_minutes = project.minutes ? project.minutes : 0;
                            project.minutes = min_to_hour(project.minutes);
                        });

                        commit("set_dashboard_data_projects", projects);
                    }
                    if (response.data.data.team_members !== undefined) {
                        let members = response.data.data.team_members;
                        members.forEach((member) => {
                            member.all_minutes = member.minutes ? member.minutes : 0;
                            member.minutes = min_to_hour(member.minutes);
                        });
                        commit("set_dashboard_data_members", members);
                    }
                })
                .catch(() => {
                    commit("toggle_loader", false);
                });
        },
        1000,
        { leading: true, trailing: false }
    ),
    getProjects({ commit, dispatch }, data) {
        // commit("toggle_loader", true);
        dispatch("project_list_api", { company_id: data.company_id });
    },
    getProjectsTrello({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("get-boards", data);
    },


    send_sync_project({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("sync-boards", data);
    },



    project_list_api: _.debounce(
        function ({ commit }, data) {
            return axios
                .post("project-list", data)
                .then((response) => {
                    if (response.data.error === false) {
                        let data = response.data.projects;
                        let project_list = {
                            active: [],
                            archived: [],
                        };
                        data.forEach((project) => {
                            project.all_minutes = project.minutes ? project.minutes : 0;
                            project.minutes = min_to_hour(project.minutes);
                            if (project.project_status === "active")
                                project_list.active.push(project);
                            else project_list.archived.push(project);
                        });
                        commit("set_projects_list", project_list);
                        commit("toggle_loader", false);
                    }
                })
                .catch(() => {
                    commit("toggle_loader", false);
                });
        },
        1000,
        { leading: true, trailing: false }
    ),
    createProject({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("project", data, config);
    },
    updateCompany({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("company-update", data, config);
    },

    updateProject({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("update-project", data, config);
    },
    archivedProject({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("archive-project", data);
    },
    restore_project({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("restore-project", data);
    },
    getEmployeeEmployers: _.debounce(function ({ commit }, data) {
        return new Promise((resolve, reject) => {
            return axios
                .post("employer-all-employees", data)
                .then((response) => {
                    // commit ( 'toggle_loader', false )
                    resolve(response);
                    let data = response.data.employer_all_employees;
                    commit("set_employer_employees", data);
                    commit("toggle_loader", false);
                })
                .catch((error) => {
                    commit("toggle_loader", false);
                    reject(error);
                });
        });
    }, 1000,
        { leading: true, trailing: false },
    ),
    get_project_data({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("show-project", data);
    },
    get_team_members: _.debounce(
        function ({ commit }, data) {
            return axios
                .post("team-members-new", data)
                .then((response) => {
                    commit("toggle_loader", true);
                    commit("set_team_members", response.data.team_members);
                    commit("set_team_members_list", response.data.team_members);
                    commit("set_team_member_with_project", response.data.team_members);
                    commit("toggle_loader", false);
                    return response.data;
                })
                .catch(() => {
                    commit("toggle_loader", false);
                });
        },
        1000,
        { leading: true, trailing: false }
    ),
    get_active_team_members_list: _.debounce(
        function ({ commit, state, dispatch }, data) {
            commit("toggle_loader", true);
            commit("set_active_loader", true);
            if (state.selected_company.user_status !== "employee" || state.selected_company.has_team > 0)
                return axios
                    .post("active-team-members-list-new", data)
                    .then((response) => {
                        commit("set_active_team_members_list", response.data);
                        commit("set_filter_active_team_member_list", response.data);
                        // commit("set_team_members_list", response.data);
                        commit("set_lead_team_members", response.data);
                        // commit("set_team_members", response.data);
                        commit("toggle_loader", false);
                        let data_company_project = {
                            company_id: data.company_id,
                        };
                        if (state.selected_company.user_status !== "employee" && state.team_members_projects_list.length == 0) {
                            dispatch("getCompanyProject", data_company_project)
                                .then((response) => {
                                    commit("set_team_members_project_list", response.data.projects);
                                    commit("toggle_loader", false);
                                })
                                .catch((error) => { });
                        }
                        if (state.selected_company.user_status !== "employee" && state.archived_team_members_list.length == 0) {
                            dispatch("get_archived_team_members_list", data)
                                .then((response_archive) => {
                                    commit("set_archived_team_members_list", response_archive.data);
                                    commit("toggle_loader", false);
                                })
                                .catch((error) => {
                                    commit("toggle_loader", false);
                                });
                        }
                        commit("set_active_loader", false);
                    })
                    .catch(() => {
                        commit("set_active_loader", false);
                        commit("toggle_loader", false);
                        commit("toggle_progress_loader", false);
                    });
        },
        1000,
        { leading: true, trailing: false }
    ),
    get_archived_team_members_list({ commit, dispatch, state }, data) {
        return axios.post("archived-team-members-list", data).then((response_archive) => {
            commit("set_archived_team_members_list", response_archive.data);
            commit("toggle_loader", false);
        }).catch((error) => {
            commit("toggle_loader", false);
        });
    },
    team_members_list_api({ commit, dispatch, state }, data) {
        if (state.selected_company.user_status !== "employee")
            return axios
                .post("active-team-members-list", data)
                .then((response) => {
                    commit("toggle_progress_loader", true);
                    dispatch("get_team_members", {
                        company_id: this._vm.$route.params.id,
                    });
                    commit(
                        "set_active_team_members_list",
                        response.data.data.team_members
                    );
                    commit("set_team_members_project_list", response.data.data.projects);
                })
                .catch(() => { });
    },
    getCompanyProject({ commit, dispatch, state }, data) {
        return axios.post("company-project", data).then((response) => {
            commit("set_team_members_project_list", response.data.projects);
            commit("toggle_loader", false);
        })
            .catch((error) => { });;
    },
    get_lead_team_members: _.debounce(
        function ({ state, commit }, data) {
            if (state.lead_team_members.length == 0) {
                commit("toggle_loader", true);
            }
            return axios
                .get("team?company_id=" + data.company_id, data)
                .then((response) => {
                    commit("toggle_loader", false);
                    commit("set_lead_team_members", response.data.data.team_members);
                    commit("set_team_members_project_list", response.data.data.projects);
                })
                .catch((error) => {
                    commit("toggle_loader", false);
                });
        },
        1000,
        { leading: true, trailing: false }
    ),
    lead_team_members_api({ commit }, data) {
        return axios.get("team?company_id=" + data.company_id, data);
    },
    user_logout({ commit }) {
        commit("toggle_loader", true);
        return axios.post("auth/backoffice/logout")
            .then(() => {
                this.$router.push({ name: 'login' });
            })
            .catch(() => {
                commit("toggle_loader", false);
            });
    },
    update_member_interval({ commit }, data) {
        // commit("toggle_progress_loader", true);
        return axios.post("change-member-interval", data);
    },
    update_member_name({ commit }, data) {
        commit("toggle_progress_loader", true);
        return axios.post("change-member-name", data);
    },
    update_member_image({ commit }, data) {
        commit("toggle_progress_loader", true);
        return axios.post("update-user-image", data);
    },
    update_member_department({ commit }, data) {
        commit("toggle_progress_loader", true);
        return axios.post("change-member-department", data);
    },
    re_invite_member({ commit }, data) {
        commit("toggle_progress_loader", true);
        return axios.post("re-invite-member", data);
    },
    invite_members_to_project(NULL, data) {
        return new Promise((resolve, reject) => {
            return axios.post("invite-members-to-projects", data)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    invite_existing_member({ commit }, data) {
        commit("toggle_progress_loader", true);
        return axios.post("invite-existing-member", data);
    },
    remove_from_not_my_project({ commit }, data) {
        commit("remove_from_not_my_project", data);
    },
    make_remove_admin({ commit }, data) {
        return axios.post("change-admin", data);
    },
    remove_member_role({ commit }, data) {
        return axios.post("remove-member-role", data);
    },
    remove_from_project({ commit }, data) {
        commit("toggle_progress_loader", true);
        return axios.post("dis-associate-member", data);
    },
    createCompany({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("company", data, config);
    },
    delete_company({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("company-destroy", data);
    },
    archive_company({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("company-archive", data);
    },
    restore_company({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("company-restore", data);
    },
    get_intelligence_report_filter({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("intelligence-report-filters", data);
    },
    get_intelligence_report({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("intelligence-report", data);
    },
    get_work_diary_video(NULL, data) {
        return axios.post("create-video", data);
    },
    get_work_diary: _.debounce(
        function ({ commit }, data, page) {
            return axios.post("work-diary", data);
        },
        1000,
        { leading: true, trailing: false }
    ),
    company_cancel_subscription({ commit, dispatch }, data) {
        commit("toggle_loader", true);
        axios
            .post("company-subscription-cancel", data)
            .then((response) => {
                commit("toggle_loader", false);
                if (response.data.error === false) {
                    dispatch("getAllCompaniesList");
                }
            })
            .catch(() => {
                commit("toggle_loader", false);
            });
    },
    company_resume_subscription({ commit, dispatch }, data) {
        commit("toggle_loader", true);
        axios
            .post("company-subscription-resume", data)
            .then((response) => {
                commit("toggle_loader", false);
                if (response.data.error === false) {
                    dispatch("getAllCompaniesList");
                }
            })
            .catch(() => {
                commit("toggle_loader", false);
            });
    },
    company_remove_subscription({ commit }, data) {
        commit("toggle_loader", true);
        axios
            .post("company-subscription-remove", data)
            .then((response) => {
                commit("toggle_loader", false);
                if (response.data.error === false) {
                    this.$vue.$router.go();
                }
            })
            .catch(() => {
                commit("toggle_loader", false);
            });
    },
    company_payments_cards({ commit }, data) {
        commit("toggle_loader", true);
        axios
            .post("company-pcards", data)
            .then((response) => {
                commit("toggle_loader", false);
                if (response.data.error === false) {
                    commit("set_company_payments_cards", response.data.payment_cards);
                }
            })
            .catch(() => {
                commit("toggle_loader", false);
            });
    },
    add_payment_card({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("pcard", data);
    },
    update_payment_card({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("update-customer-plan", data);
    },
    billing_info_form({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("billing-info", data);
    },
    account_info_form({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("update-user", data);
    },
    make_default_card({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("update-pcard", data);
    },
    remove_card({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("delete-pcard", data);
    },
    archive_member({ commit, state, dispatch }, data) {
        commit("toggle_loader", true);
        return axios.post("archive-member", data)
    },
    restore_member({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("restore-member", data);
    },
    get_user_account_info({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("user-account-settings", data);
    },
    remove_screenshoot({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("screenshot-delete", data);
    },
    getTeamforLeader({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("leader-project-members", data);
    },
    updateTeamMemberforLeader(NULL, data) {
        return axios.post("assign-team-member", data);
    },
    get_payment_history({ commit }, data) {
        return axios
            .post("payment-history", data)
            .then((response) => {
                if (response.data.error === false) {
                    commit("set_payment_history", response.data.invoices);
                }
            })
            .catch((error) => {
                if (error.response.data.error === false) {
                    commit("set_payment_history", error.response.data.invoices);
                }
            });
    },
    get_paypal_invoice_histroy({ commit }, data) {
        return new Promise((resolve, reject) => {
            return axios
                .post("paypal-subscription-billing", data)
                .then((response) => {
                    if (response.data.error === false) {
                        // commit("set_paypal_payment_history", response.data.billing);
                        resolve(response.data);
                    }
                })
                .catch((error) => {
                    if (error.response.data.error === false) {
                        // commit("set_paypal_payment_history", error.response.data.billing);
                        reject(error);
                    }
                });
        });
    },
    cancel_paypal_subscription({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit("toggle_loader", true);
            return axios
                .post("paypal-subscription-cancel", data)
                .then((response) => {
                    if (response.data.error === false) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    if (error.response.data.error === false) {
                        reject(error);
                        commit("toggle_loader", false);
                    }
                });
        });
    },
    reactive_paypal_subscription({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit("toggle_loader", true);
            return axios
                .post("paypal-subscription-reactivated", data)
                .then((response) => {
                    if (response.data.error === false) {
                        resolve(response);
                    }
                })
                .catch((error) => {
                    if (error.response.data.error === false) {
                        reject(error);
                        commit("toggle_loader", false);
                    }
                });
        });
    },
    forgotPassword(NULL, data) {
        return axios.post("auth/forgot-password", data, {
            responseType: "json",
        }).then(response => {
            return response;
        });
    },

    project_task_list: _.debounce(
        function ({ commit }, data, page) {
            return axios.post("backoffice/project-task-list-new", data.obj);
        },
        2000,
        { leading: true, trailing: false }
    ),

    invoice_download(NULL, data) {
        return axios.post("download-invoice", data, {
            headers: { Accept: "application/pdf" },
            responseType: "arraybuffer",
        });
    },
    delete_shots_array(NULL, data) {
        return axios.post("delete-shots-array", data);
    },
    update_status: _.debounce(function (NULL, data) {
        return axios.post("backoffice/task-status-change", data);
    }, 1000,
        { leading: true, trailing: false }
    ),
    get_report_data({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("get-report", data);
    },
    update_task({ commit }, data) {
        // commit("toggle_loader", true);
        return axios.post("backoffice/update-task", data);
    },
    add_manual_time({ commit }, data) {
        return axios.post("backoffice/add-manual-time", data);
    },
    member_assign_projects({ commit }, data) {
        commit("toggle_loader", true);
        return axios.post("members-projects", data);
    },
    updateTitle({ commit }, data) {
        return axios.post("backoffice/task-update-meta-data", data);
    },
    updateDescription({ commit }, data) {
        return axios.post("backoffice/task-update-meta-data", data);
    },
    updateDate({ commit }, data) {
        return axios.post("backoffice/task-update-meta-data", data);
    },
    delete_member({ commit }, data) {
        commit("toggle_progress_loader", true);
        return axios.post("delete-member", data)
    },

    createSetupIntent({ commit }, data) {
        return axios.post("create-setup-intent", data);
    },

    time_sheet({ commit }, data) {
        return axios.post("time-sheet", data);
    },
    company_task: _.debounce(
        function ({ commit }, data) {
            return axios.post("backoffice/get-tasks-company", data);
        },
        1000,
        { leading: true, trailing: false }
    ),
    custom_log_real_time_monitor({ commit }, data) {
        return axios.post("real-time-monitoring-custom-logs", data);
    },
    get_attendance({ commit, dispatch }, data) {
        commit("toggle_loader", true);
        return axios.post("attendence-report", data);
    },

    createLink({ commit, dispatch }, data) {
        return axios.post("create-invite-link", data);
    },

    last_company_record({ commit, dispatch }, data) {
        let company = {
            company_id: data,
        };
        return axios.post("last-company-history", company);
    },

    notificationChange({ commit, dispatch }, data) {
        return axios.post("change-notification-status", data);
    },

    getLastWorkingDays: _.debounce(
        function ({ commit }, data, page) {
            return axios.post("get-last-working-day", data)
        },
        1000,
        { leading: true, trailing: false }
    ),

    getLastWorkingDaysMember: _.debounce(
        function ({ commit }, data, page) {
            return axios.post("selected-date-members", data)
        },
        1000,
        { leading: true, trailing: false }
    ),

    getTeamMemberList({ commit, dispatch }, data) {
        return axios.post("get-team-member-list", data);
    },

    paypalSubscriptionCreate({ commit, dispatch }, data) {
        commit("toggle_loader", true);
        return axios.post("paypal-subscription-create", data);
    },

    paypalSubscriptionUpdate({ commit, dispatch }, data) {
        commit("toggle_loader", true);
        return axios.post("paypal-subscription-revise", data);
    },
    save_member_face({ commit }, data) {
        return new Promise((resolve, reject) => {
            return axios
                .post("facial_registeration", data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    get_previous_image: _.debounce(function ({ commit }, data) {
        return new Promise((resolve, reject) => {
            return axios
                .post("user-face-image", data)
                .then((response) => {
                    resolve(response.data.file_path);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }, 1000,
        { leading: true, trailing: false }
    ),
    get_facial_attendence_data({ commit }, data) {
        return new Promise((resolve, reject) => {
            return axios
                .post("get-facial-attendance", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    get_facial_attendance_graph({ commit }, data) {
        return new Promise((resolve, reject) => {
            return axios
                .post("get-facial-attendance-graph", data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    delete_facial_attandence({ commit }, data) {
        return new Promise((resolve, reject) => {
            return axios
                .post("delete-facial-attandance", data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
};