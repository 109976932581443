import store from '../../store'

export default {
    store,
    user () {
        return new Promise ( resolve => {
            resolve ( store.state.auth.user );
        } )
    },
    check_partner_id () {
        return new Promise ( resolve => {
            let response = false;
            if ( store.state.auth.user.partner_id ) {
                response = true;
            }
            resolve ( response );
        } )
    },
    check_status () {
        return new Promise ( resolve => {
            let response = false;
            if ( store.state.custom.selected_company !== "null" ) {
                if(store.state.custom.selected_company.has_team > 0){
                    response = false;
                }else if ( store.state.custom.selected_company.user_status === "employee") {
                    response = true;
                }
            }
            resolve ( response );
        })
    },
    check_admin () {
        return new Promise ( resolve => {
            let response = false;
            if ( store.state.custom.selected_company !== "null" ) {
                if ( store.state.custom.selected_company.user_status === "secondary_user" ) {
                    response = true;
                }
            }
            resolve ( response );
        } )
    },
    check_permission (route) {
        return new Promise ( resolve => {
            let response = false;
            if (route === "members-group-monitor" && store.state.custom.selected_company.permission) {

                if (store.state.custom.selected_company.permission.team_member) {

                    response = true
                } else {

                    response = false
                }
            }
            if (route === "Dashboard" && store.state.custom.selected_company.permission) {
                if (store.state.custom.selected_company.permission.dashboard) {
                    response = true
                } else {
                    response = false
                }
            }
            if (route === "TeamMembers" && store.state.custom.selected_company.permission) {
                if (store.state.custom.selected_company.permission.team_member) {
                    response = true
                } else {
                    response = false
                }
                // response = !!store.state.custom.selected_company.permission.team_member;
            }
            if (route === "Projects" && store.state.custom.selected_company.permission) {
                if (store.state.custom.selected_company.permission.project) {
                    response = true
                } else {
                    response = false
                }
            }
            if (route === "Task" && store.state.custom.selected_company.permission) {
                if (store.state.custom.selected_company.permission.task) {
                    response = true
                } else {
                    response = false
                }
            }
            if (route === "Billing" && store.state.custom.selected_company.permission) {
                if (store.state.custom.selected_company.permission.billing) {
                    response = true
                } else {
                    response = false
                }
            }

            // if ( store.state.custom.selected_company.user_status === "secondary_user" ) {
            //   response = true;
            // }
            resolve ( response );
        } )
    },
    check_company_exist () {
        return new Promise ( resolve => {
            let response = false;
            if ( store.state.custom.companies_list ) {
                if ( store.state.custom.companies_list.length > 0 )
                    response = true;
            }
            resolve ( response );
        } )
    },
    check_project_exist () {
        let response = false;
        if ( Object.keys ( store.state.custom.projects_list ).length !== 0 ) {
            if ( store.state.custom.projects_list.active.length > 0 )
                response = true;
        }
        return response
    },
    check_selected_company ( to, from ) {
        return new Promise ( resolve => {
            resolve (
                to.params.id === from.params.id ||
                store.state.custom.companies_list.find ( c => c.id === to.params.id )
            );
        } )
    },
    check_selected_company_subscription () {
        let response = true;
        if ( store.state.custom.selected_company && store.state.custom.selected_company.user_status !== 'employee' ) {
            response = store.state.custom.selected_company.company_subscription_status;
        }
        return response;
    }
}
