const emailRules = [
    (v) =>
        /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/.test(v) || "E-mail must be valid",
];
const required = [(v) => !!v || "Required."];

const companyNameRules = [
    (v) => !!v || "Required",
    (v) =>
        /^[A-Za-z0-9\s]+$/.test(v) || "Comapany name must be valid",
    (v) => (v && v.length >= 2 && v.length <= 80) || "Comapany name must between 2 - 80"
];

const TeamSize = [
    (v) => !!v || "Required",
    (v) =>
        /^[0-9\s]+$/.test(v) || "Team size must be numeric",
    (v) => (v && v.toString().length >= 1) || "Minimum team size 1"
];

const ProjectNameRules = [
    (v) => !!v || "Required",
    (v) => /^[A-Za-z0-9\s]+$/.test(v) || "Invalid Project Name",
    (v) => (v && v.length >= 3 && v.length <= 50) || "Project name must between 3 - 50"
];

const DesignationRules = [
    (v) => !!v || "Required",
    (v) => /^[A-Za-z\s]+$/.test(v) || "Invalid Designation",
    (v) => (v && v.length >= 4 && v.length <= 50) || "Designation name must between 4 - 50"
];

const UserNameRules = [
    (v) => !!v || "Required",
    (v) => /^[A-Za-z0-9\s]+$/.test(v) || "Invalid User Name",
    (v) => (v && v.length >= 3 && v.length <= 50) || "User name must between 3 - 50"
];

const phoneNumberRules = [
    (v) => !!v || "Required",
    (v) => /^[0-9\s]+$/.test(v) || "Invalid Phone Number",
    (v) => (v && v.length <= 16) || "Phone number must not be greater than 16 digit"
]

const passwordRules = [
    (v) => (v && v.length >= 6) || "Password must be greater than or equal to 6 digit"
]

export {
    emailRules,
    required,
    companyNameRules,
    TeamSize,
    ProjectNameRules,
    DesignationRules,
    UserNameRules,
    phoneNumberRules,
    passwordRules
};