<template>
  <v-snackbar class="new_class" v-model="localSnackbar" top right :color="color" :timeout="2000">
    <span v-html="message"></span>
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="updateSnacbarState">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    snackbar: {
      type: Boolean,
      defualt: false,
    },
    message: {
      type: String,
    },
    color: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      localSnackbar: false,
    };
  },

  methods: {
    updateSnacbarState: function () {
      this.$emit("update:snackbar", false);
    },
  },
  watch: {
    //assign snacbar prop to local snacbar data variable
    snackbar: function () {
      // console.log(this.snackbar);
      this.localSnackbar = this.snackbar;
    },
    localSnackbar: function () {
      if (this.localSnackbar == false) {
        this.$emit("update:snackbar", false);
      }
    },
  },
};
</script>
