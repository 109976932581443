<template>
  <div v-if="companySuspendedStatus">
    <suspended-company />
  </div>
  <div v-else>
    <Loader v-show="loader" />
    <div
      v-if="
        selected_company.paypal_approve_link_update &&
        selected_company.user_status == 'employer'
      "
      class="paypal_approve_link"
      @click="moveToPaypalRevision"
    >
      Please Approve Your Subscription Revision By
      <b> <u> Clicking Here. </u></b>
    </div>

    <router-view
      v-if="is_company_on_trial || company_subscription || !team_members.length"
    ></router-view>
    <subscription-expired v-else />
  </div>
</template>
<script>
import { baseURL } from "../../assets/js/urls";
import { mapState } from "vuex";
import cookies from "js-cookie";
import Cookie from "js-cookie";
import { sha256 } from "js-sha256";
export default {
  name: "CompanyLayout",
  components: {
    SubscriptionExpired: () =>
      import("@/components/includes/SubscriptionExpired"),
    SuspendedCompany: () => import("@/components/includes/SuspendedCompany"),
    Loader: () => import("@/components/includes/Loader"),
  },
  data() {
    return {
      online_interval: null,
      img_url: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("custom", ["selected_company", "companies_list"]),
    selectedCompany() {
      let company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) {
        this.$store.commit("custom/set_selected_company", company);
        return company;
      } else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    company_subscription() {
      return this.selectedCompanyUserStatus !== "employee"
        ? this.selectedCompany.company_subscription_status
        : true;
    },
    companySuspendedStatus() {
      if (this.selectedCompanyUserStatus !== "employee") {
        return this.selectedCompany.active_status == true ? true : false;
      }
      return false;
    },
    is_company_on_trial() {
      return this.selectedCompanyUserStatus !== "employee"
        ? this.selectedCompany.check_trial_period_status
        : true;
    },
    selectedCompanyUserStatus() {
      return this.selectedCompany.user_status;
    },
    selectedCompanyUserHasTeam() {
      return this.selectedCompany.has_team;
    },
    selectedCompanyUserAllowedToViewBackoffice() {
      return this.selectedCompany.view_backoffice;
    },
    userAllowed() {
      return (
        this.selectedCompanyUserStatus !== "employee" ||
        this.selectedCompanyUserHasTeam
      );
    },
    team_members() {
      return this.$store.state.custom.team_members;
    },
    routerKey() {
      return this.$store.state.custom.routerKey;
    },
    teamLeadMembers() {
      let lead =
        this.$store.state.custom.lead_team_members.filter(
          (member) => member.member_status === "active"
        ) || [];
      return lead;
    },
    loader() {
      return this.$store.state.custom.show_loader;
    },
  },

  sockets: {
    connect() {
      if (
        this.$store.state.auth.user &&
        this.selectedCompany &&
        this.userAllowed &&
        this.selectedCompanyUserStatus
      ) {
        this.$socket.emit("backofficeLogin", {
          user_id: this.$store.state.auth.user.id,
          name: this.$store.state.auth.user.name,
          company_id: this.$route.params.id,
          user_status: this.selectedCompanyUserStatus,
          // auth_token:this.$store.state.auth.user.auth_token
        });
      }

      this.$socket.emit("employeeBackofficeLogin", {
        id: this.$store.state.auth.user.id,
        name: this.$store.state.auth.user.name,
        // auth_token:this.$store.state.auth.user.auth_token
      });
    },
    "online-user"(user) {
      if (this.selectedCompanyUserHasTeam) {
        user = this.teamLeadMembers.find(
          (tm) => tm.id === user.user_id && tm.name === user.name
        );
      } else if (this.selectedCompanyUserStatus === "employee") {
        user = undefined;
      } else if (this.selectedCompanyUserStatus !== "employee") {
        user = this.team_members.find(
          (tm) => tm.id === user.user_id && tm.name === user.name
        );
      }
      // if ( user ) this.$root.$emit("snack_bar", {'show' : true, 'message' : user.name + " came online!", 'snackbarColor' : 'blue'});
    },

    disconnectedUser(user) {
      if (this.selectedCompanyUserHasTeam) {
        user = this.teamLeadMembers.find(
          (tm) => tm.id === user.user_id && tm.name === user.name
        );
      } else if (this.selectedCompanyUserStatus === "employee") {
        user = undefined;
      } else if (this.selectedCompanyUserStatus !== "employee") {
        user = this.team_members.find(
          (tm) => tm.id === user.user_id && tm.name === user.name
        );
      }
      // if ( user ) this.$root.$emit("snack_bar", {'show' : true, 'message' : user.name + " went offline!", 'snackbarColor' : 'blue'});
    },

    refresh_company(userInfo) {
      let user_id = sha256(this.$store.state.auth.user.id);
      if (user_id == userInfo) {
        this.$store.dispatch("custom/getAllCompaniesList");
      }
    },

    task_completed(userInfo) {
      if (this.$route.params.id === userInfo.company_id && userInfo.task_id) {
        let data = {
          message: userInfo.message,
          page_status: "task",
        };
        this.$root.$emit("notification", data);
      }
    },

    backoffice_task(userInfo) {
      let data = userInfo.split("_");
      if (this.$route.params.id === data[1] && data[3]) {
        let data = {
          message: "You have assgined new task",
          page_status: "task",
        };
        this.$root.$emit("notification", data);
      }
    },

    update_task_member_backoffice(userInfo, emityKey, action) {
      let data = emityKey.split("_");
      if (this.$route.params.id === data[1]) {
        let data = {
          message:
            action === "add"
              ? "You have assgined new task!"
              : "You have removed from task",
          page_status: "task",
        };
        this.$root.$emit("notification", data);
      }
    },
    task_comment_notify(commentDetails) {
      if (this.$route.params.id === commentDetails.company_id) {
        let data = {
          message: `${commentDetails.name} added comment : ${commentDetails.comment_text}`,
          page_status: "comment",
          task_id: commentDetails.task_id,
          comment_id: commentDetails.comment_id,
        };
        this.$root.$emit("notification", data);
      }
    },
  },
  async mounted() {
    window.addEventListener("offline", () => {
      this.$root.$emit("snack_bar", {
        show: true,
        message: "No Internet Connection",
        snackbarColor: "red",
      });
    });
    window.addEventListener("online", () => {
      this.$root.$emit("snack_bar", {
        show: true,
        message: "Internet Connected",
        snackbarColor: "green",
      });
    });
    if (this.$route.params.id !== this.selectedCompany.id) {
      this.$route.params.id = this.selectedCompany.id;
    }
    if (
      this.$route.params.id &&
      !this.selectedCompanyUserAllowedToViewBackoffice
    ) {
      setTimeout(() => {
        this.$root.$emit("snack_bar", {
          show: true,
          message:
            "You're not allowed to step into this company, please contact your employer!",
          snackbarColor: "red",
        });
      }, 1000);
      let data = {
        companyName: this.selectedCompany.name,
        backofficePermission: this.selectedCompany.view_backoffice,
      };
      this.$store.commit("custom/set_backoffice_permission", data);
      return this.$router.push({ name: "CompaniesListing" });
    }
    this.$root.$on("notification", (data) => {
      if (
        window.Notification &&
        Notification.permission !== "denied" &&
        this.$store.state.auth.user.notification_status
      ) {
        Notification.requestPermission(function () {
          // status is "granted", if accepted by user
          new Notification("Staff Timer App", {
            body: data.message,
            icon:
              "https://stafftimerapp.com/wp-content/uploads/2019/08/Blue-new-logo-01.png",
            img:
              "https://stafftimerapp.com/wp-content/uploads/2019/08/Blue-new-logo-01.png",
          });
        });
      }
      if (this.$route.name === "Task" && data.page_status === "task") {
        this.$root.$emit("task_list_updated", true);
      }
      if (this.$route.name === "Task" && data.page_status === "comment") {
        this.$root.$emit("comments", data.task_id, data.comment_id);
      }
    });

    // if ( this.$store.state.pf.companies.includes ( this.$route.params.id ) )
    //   await this.verifyUserProfilePicture ();
    if (this.userAllowed) {
      this.getOnlineEmployeesFromNode();
      this.online_interval = setInterval(
        () => this.getOnlineEmployeesFromNode(),
        10000
      );
    }

    this.backOfficeEmployeeLoginFromNode();
  },
  destroyed() {
    clearInterval(this.online_interval);
  },
  methods: {
    moveToPaypalRevision() {
      if (this.selected_company.paypal_approve_link_update) {
        let url = this.selected_company.paypal_approve_link_update;
        window.open(url, "_blank");
      }
    },
    // method to fetch and store online members
    getAllOnlineEmployees() {
      let auth_token = this.user.auth_token;
      let company_id = this.$route.params.id;
      let user_id = this.user.id;
      this.$socket.emit(
        "getAllEmployees",
        { company_id, user_id, auth_token },
        (data) => {
          if (data != null) {
            let online_mem = this.selectedCompanyUserHasTeam
              ? data.filter(
                  ({ user }) =>
                    !!this.teamLeadMembers.find(
                      // (tm) => tm.id === user.user_id && tm.name === user.name
                      (tm) => tm.id === user.user_id
                    )
                )
              : data;
            online_mem = online_mem.map((item) => (item.user ? item.user : {}));
            this.$store.commit("custom/store_socket_employee", online_mem);
          }
        }
      );
    },
    getTeamLeadMembers(data) {
      this.$store
        .dispatch("custom/lead_team_members_api", {
          company_id: data.company_id,
        })
        .then((response) => {
          this.$store.commit(
            "custom/set_lead_team_members",
            response.data.team_members
          );
        })
        .catch((error) => {
          if (error.response.data.error) {
            console.log("company layout set lead members in catch");
            this.$store.commit("custom/set_lead_team_members", {});
          }
        });
    },
    getTeamMembers(data) {
      if (!this.userAllowed) return;
      this.$store.dispatch("custom/tag_list_api", {
        company_id: data.company_id,
      });
      this.$store.dispatch("custom/team_members_list_api", {
        company_id: data.company_id,
      });
    },
    loadProjectsPageData(data) {
      this.$store.dispatch("custom/project_list_api", {
        company_id: data.company_id,
      });
      if (this.selectedCompany.user_status !== "employee") return;
      this.$store.dispatch("custom/get_team_members", {
        company_id: data.company_id,
      });
      this.$store.dispatch("custom/employer_all_employees_api", {
        company_id: data.company_id,
      });
    },

    backOfficeEmployeeLoginFromNode() {
      this.$socket.emit("employeeBackofficeLogin", {
        id: this.$store.state.auth.user.id,
        name: this.$store.state.auth.user.name,
        // auth_token:this.$store.state.auth.user.auth_token
      });
    },
    getOnlineEmployeesFromNode() {
      if (!this.$socket.connected && this.$socket.disconnected) {
        if (
          this.$store.state.auth.user &&
          this.selectedCompany &&
          this.selectedCompanyUserStatus
        ) {
          this.$socket.emit("backofficeLogin", {
            user_id: this.$store.state.auth.user.id,
            name: this.$store.state.auth.user.name,
            company_id: this.$route.params.id,
            user_status: this.selectedCompanyUserStatus,
            // auth_token:this.$store.state.auth.user.auth_token
          });
        }
      }
      // case in which auth_token is already there, emits for online employees
      if (this.user.auth_token && this.user.auth_token != null) {
        this.getAllOnlineEmployees();
      } else {
        // Dispatches an action to get auth_token via axios request and then emits for online employees
        this.$store
          .dispatch("auth/update_auth_token")
          .then((res) => {
            this.getAllOnlineEmployees();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async verifyUserProfilePicture() {
      if (!this.$store.state.auth.user.image) this.redirectToUpdateImage();
      this.img_url =
        baseURL.API_URL + "storage/" + this.$store.state.auth.user.image;
      await this.generateBase64();
    },
    isImageValid(imageBase64) {
      if (this.$socket.connected) {
        let parts = imageBase64.split(";");
        let FaceApiBase64 = parts[1].split(",")[1];
        this.$socket.emit("is_image_valid", FaceApiBase64, (data) => {
          if (data !== true) this.redirectToUpdateImage();
        });
      }
    },
    generateBase64() {
      return new Promise((resolve, reject) => {
        const _this = this;
        fetch(this.img_url)
          .then((response) => response.blob())
          .then((blob) => {
            const reader = new FileReader();
            reader.onload = async function () {
              await _this.isImageValid(this.result); // <--- `this.result` contains a base64 data URI
              resolve();
            };
            reader.readAsDataURL(blob);
            reader.onerror = function () {
              reject();
            };
          });
      });
    },
    redirectToUpdateImage() {
      if (this.$route.name !== "update-image")
        this.$router.push({
          name: "update-image",
          params: { id: this.$route.params.id },
        });
    },
  },
};
</script>

<style>
/* All Drop Down List Left Allign */
.v-list {
  text-align: left !important;
}
.uner_info_tooltip,
.v-tooltip__content {
  opacity: 1 !important;
}

/* All Date Range Picker List Global Styling */
#custom-list-style {
  border: 2px solid #cbdaf2;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 25px 35px -20px rgb(172 184 207 / 34%);
}

/* All Drop Down List Global Styling */
.v-autocomplete__content {
  border: 2px solid #cbdaf2;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 25px 35px -20px rgb(172 184 207 / 34%);
}
.v-list-item:hover {
  background-color: #e8eff9;
}
.labels {
  height: 14px;
  margin-bottom: 5px;
  color: #36454f;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}
</style>

<style scoped>
.paypal_approve_link {
  background: #fff176;
  padding: 2px;
  cursor: pointer;
}
</style>
