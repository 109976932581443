
export function min_to_hour ( mins ) {
    return { hrs : Math.floor ( mins / 60 ), mins : ( mins % 60 ) }
}

export function confirm_dialog ( text, buttons, callback ) {
    swal ( {
        title : "Are you sure?",
        // content:true,
        text : text,
        icon : "warning",
        buttons : buttons,
        dangerMode : true
    } )
    .then ( ( willDelete ) => {
        if ( willDelete ) {
            if ( buttons !== true ) {
                callback ( willDelete )
            } else {
                callback ( true )
            }
        } else {
            callback ( false )
        }
    });
}
export function stripe_key () {
    return process.env.VUE_APP_STRIPE_KEY
}


export function notify ( title, message, type , delay=500 , url='' ) {
    $.notify ( {
        // options
        //icon: 'fa fa-trash',
        title : "<strong>" + title + "</strong>",
        message : message,
        url: url,
        target: '_blank'
    }, {
        // settings
        element : 'body',
        position : null,
        type : type,
        allow_dismiss : true,
        newest_on_top : true,
        showProgressbar : false,
        placement : {
            from : "top"
        },
        offset : {
            x : 20,
            y : 85
        },
        spacing : 5,
        z_index : 99999,
        delay : delay,
        timer : 1000,
        url_target : '_blank',
        mouse_over : null,
        animate : {
            enter : 'animated fadeInDown',
            exit : 'animated fadeOutUp'
        },
        onShow : null,
        onShown : null,
        onClose : null,
        onClosed : null,
        icon_type : 'class',
        template : '<div class="main-notification">' + '<div data-notify="container" class="col-xs-11 alert alert-{0}" role="alert">' +
            '<button type="button" aria-hidden="true" class="close mc ml-2" data-notify="dismiss">×</button>' +
            '<span data-notify="icon"></span> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message" class="mr-2">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            '</div>' + '</div>'
    } );
}

export function disabled_btn ( event ) {
    let button = event;
    let loadingText = "<i class='fa fa-spinner fa-spin'></i> Processing...";
    if ( button.innerHTML !== loadingText ) {
        button.setAttribute ( 'original-text', button.innerHTML );
        button.innerHTML = loadingText;
        button.setAttribute ( 'disabled', true )
    }
}

export function postDataToSlack ( store, data ) {
    let name = null;
    let id = null;
    let email = null;
    if ( store.state.auth.user.id ) {
        id = store.state.auth.user.id;
        name = store.state.auth.user.name;
        email = store.state.auth.user.email
    }
    let payload = JSON.stringify ( {
        'attachments' : [ {
            'fallback' : 'Backoffice Exception ERROR',
            'pretext' : '*Backoffice Exception ERROR*',
            'color' : 'danger',
            'fields' : [ {
                'title' : 'Name',
                'value' : name,
                'short' : false
            },
                {
                    'title' : 'Email',
                    'value' : email,
                    'short' : false
                },
                {
                    'title' : 'ID',
                    'value' : id,
                    'short' : false
                },
                {
                    'title' : 'Info',
                    'value' : data.info || null,
                    'short' : false
                },
                {
                    'title' : 'Message',
                    'value' : data.message || null,
                    'short' : false
                },
                {
                    'title' : 'Stack',
                    'value' : data.stack || null,
                    'short' : false
                }
            ]
        } ]
    } );
    $.ajax ( {
        url : 'https://chat.pf.com.pk/hooks/cstjn9rnxfrg5x85iqmkddonuh',
        type : 'POST',
        data : 'payload=' + payload,
        contentType : 'application/json; charset=utf-8',
        dataType : 'json'
    } );
}


