var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-card',{staticClass:"mx-auto mt-16",attrs:{"width":"430"}},[_c('a',{attrs:{"href":"https://stafftimerapp.com/","target":"_blank"}},[_c('img',{staticClass:"header-img",attrs:{"src":_vm.imgSource,"width":"380","height":"180"}})]),_c('p',{staticClass:"minutes-hours-days"},[_vm._v("A minute can make a difference")]),_c('v-form',{ref:"form",staticClass:"mt-10 form",attrs:{"id":"login-form","autocomplete":"off"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"ml-8 mr-8",attrs:{"id":"login-email-input","autofocus":"","rules":_vm.emailRules,"label":"Email Address","append-icon":"mdi-email","required":"","autocomplete":"off"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('v-text-field',{staticClass:"ml-8 mr-8",attrs:{"id":"login-password-input","rules":_vm.required,"label":"Password","append-icon":_vm.visible ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visible ? 'text' : 'password',"required":"","autocomplete":"off"},on:{"click:append":function($event){_vm.visible = !_vm.visible}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{class:_vm.$vuetify.breakpoint.xs ||
          _vm.$vuetify.breakpoint.sm ||
          _vm.$vuetify.breakpoint.md
            ? 'd-flex-wrap'
            : 'd-flex'},[_c('div',{staticClass:"ml-auto",class:_vm.$vuetify.breakpoint.xs ||
            _vm.$vuetify.breakpoint.sm ||
            _vm.$vuetify.breakpoint.md
              ? ''
              : 'mt-4'},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"subtitle-1 float-right mr-8 text-decoration-none blue--text",attrs:{"id":"forget-password-link"}},'span',attrs,false),on),[_vm._v(" Forget Password? ")])]}}]),model:{value:(_vm.forget_dialog),callback:function ($$v) {_vm.forget_dialog=$$v},expression:"forget_dialog"}},[_c('div',[_c('ForgetPassword',{attrs:{"forget_dialog":_vm.forget_dialog}})],1)])],1)]),_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"white--text text-capitalize text-white mt-5",class:_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs
              ? 'subtitle-1'
              : 'title',attrs:{"id":"login-btn","color":"#2758f6","width":"80%","x-large":_vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.lg,"medium":_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,"disabled":!_vm.valid || _vm.loginLoader},on:{"click":_vm.login}},[(_vm.loginLoader)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"20"}}):_vm._e(),_vm._v(" Login")],1)],1),_c('p',{staticClass:"ml-11 mt-5 mb-10"},[_vm._v(" Don't have an account? "),_c('a',{staticClass:"text-decoration-none",attrs:{"id":"signup-link","href":"https://stafftimerapp.com/start-free-trial/","target":"_blank"}},[_vm._v(" Sign Up ")])])],1)],1),_c('Snackbar',{attrs:{"snackbar":_vm.snackbar,"message":_vm.message,"color":_vm.snackbarColor},on:{"update:snackbar":function($event){_vm.snackbar=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }