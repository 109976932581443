<template>
<v-app>
    <EmailInviteMemberSetPassword />
</v-app>
</template>

<script>
    export default {
        name: "email-invite-member-set-password",
        layout : 'login',
        components : { EmailInviteMemberSetPassword : () => import('@/components/login/EmailInviteMemberSetPassword') }
    }
</script>

<style scoped>

</style>
