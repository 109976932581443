<template>
  <div id="app">
    <component :is="layout" />
  </div>
</template>
<script>
const default_layout = "app";
export default {
  name: "app",
  components: {
    "simple-layout": () => import("@/components/layouts/LoginLayout"),
    "app-layout": () => import("@/components/layouts/AppLayout"),
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
  mounted() {
    window.addEventListener("storage", function (ev) {
      if (ev.key !== "reload-open-tabs") return; // ignore other keys
      const message = JSON.parse(ev.newValue);
      if (!message) return; // ignore empty msg or msg reset

      // here you act on messages.
      if (message.command === "reload") location.reload();
    });
  },
};
</script>

<style>
#app {
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #fafafa;
}
</style>
