const state = () => ({
  companies: [],
  keysData: [],
  userKeyData : []
});
const mutations = {
  resetKeys(state) {
    state.keysData = [];
  },
  keysUpdate(state) {
    state.keysData = [
      "a6babd73391f252a8b9e660b1b0d5521976ba7eebe603bc236569f50cf642ebf",
      "9c14d69c5147735bf43a6a2ca39ec0687279eff017962e63c5ed9475fb616bda",
      "952603ffca64b27935f8031814bbc0edae23cf774414e177c15f8a80cc1ae3d5",
      "dda2f6000dd7b88af7a0c42a811e85fb95d848a70ea47e51183ec0dcd4eaea31",
      "fa1fda1eece6c8419f424a1bcd78c372a442581e2c9ecb86605d80127d3c607c",
      "67b1d315044f94fa001d3f1fd9b15d9ae8ea6069b0cc4e279b58a6ef5a5b90d9",
      "bba847bc02be955aa22435d7749cfce34e168e1720fd95dbfaa2f21c697345b5",
      "87e3188eab9909b2a2a39de614a61e750900cc7a1f57775daf073517d83ac2e0",
      "3a5f30448e44c3dd2f5550e2a2139d8b74006ee7f45ebd66063e5f2065da7a01",
      "c8132fc786fa0199a51b0b824da1d793704baab5759b2086e018c542ac278fb9",
      "dbf5828519c2a0a9aacceb42f2020553ceb59878732ecde34779bb85630835a0",
      "856d4aed24a80354f43435c23932978b6351c8b10586d35896fe37b7b6308276",
      "ae4ad8a4a1d722cd0337b750a9381fd0eaa43e4d1d39de9cdf1b6e3a168aeab5",
      "889581a2234d045ed8afcdb8cc904e4a6024b1edb617846a570f542cd405d039",
      "aa6da373a43fb112452eca668304f12540e9706939a1c9788891018e45056308",
      "ebbe98abe2c206313d91048a217c8f4f38be64a2480613faaae665b920c5d6f5",
      "1888aeeb0da3c08d70cf32dc7c3cdd14abc7a0240238dd30cb9f2c604df425db",
      "9fa365d2e1f012e34eb863eb413f058cdb0e247faa91049c03c4256f945dcd47",
      "9cf26aeea912f7d92ecb3cd13a84698818fe4384c489207d628416a8fb50a517",
      "2dc6a8f81704f7cb78b59d78c38d5429b60b5371ea71f760b2239bb8789186ec",
      "6cda30bf390679b851e25a5052ffcd837e8a1c2e8b6db246ea96f87855071fa4",
      "a06805ff6c8614b755e94fe092a08d32c0fa86468b8c03a9a6e899a7c374474c",
      "a529b6b40987619532c913da7f813e6a2c3645ea98788e360eb3350a6d65defc",
      "1dfbddfceffbf63a5fd93744526bef93042e8c45925cbd7830ca62ec5ad1391d",
      "641fc8afba620b710f93088d00bb009fd2d77fb451a3dabe319af18d5f72275f",
      "402f527bb378f54e1a02de679a310edea6d8992c07e5ac6d151adfca788bd7aa",
    ];
    state.userKeyData = [
      "a26f17305b843e1e40eca8f1001aae36ac3a1c35fb1ab697a71da5aa560d4b8f",
      "6de2ae0e03658ed3c0d8165e7b5a662baaf151f06f0515fbf968098018c2e0f9",
    ];
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};