<!-- System: STA
    Purpose: This compoment will display all 
            company listing where you can add,
            edit companies etc
-->

<template>
  <v-app>
    <!----------------------------- start button header --------------->
    <v-row class="mt-12">
      <!-- Active And Archived Companies tab/Button -->
      <v-col cols="12" sm="6" md="3" offset-md="1">
        <v-btn
          dark
          elevation="5"
          :class="{
            'active-custom-btn': isActive,
            'inactive-custom-btn': !isActive,
          }"
          @click="isActive = true"
        >
          Active
        </v-btn>
        <v-btn
          dark
          elevation="5"
          :class="{
            'active-custom-btn': !isActive,
            'inactive-custom-btn': isActive,
          }"
          @click="isActive = false"
          class="ml-3"
        >
          Archived
        </v-btn>
      </v-col>
      <!-- Search Bar For Company -->
      <v-col cols="12" sm="6" offset-md="2" md="3">
        <v-text-field
          outlined
          dense
          autocomplete="off"
          id="search"
          v-model="search"
          ref="search"
          color="primary"
          label="Search in Company"
          class="px-5"
        ></v-text-field>
      </v-col>
      <!-- Add New Company Button -->
      <v-col cols="12" sm="6" md="2">
        <v-btn
          dark
          elevation="10"
          class="active-custom-btn px-16 px-md-10 px-lg-5"
          @click.stop="addCompany()"
        >
          <span class="mdi mdi-plus">Add Company </span>
        </v-btn>
      </v-col>
    </v-row>
    <!----------------------------- Start Company Compenents --------------->
    <v-container>
      <v-app>
        <div
          class="d-flex justify-center mt-16"
          v-if="current_archived_page_companies.length == 0 && !isActive"
        >
          <NoCompanyFound />
        </div>
        <div
          class="d-flex justify-center mt-16"
          v-if="current_page_companies.length == 0 && isActive"
        >
          <NoCompanyFound />
        </div>
        <v-row>
          <!-- Active Companies Component -->
          <ActiveCompanies
            :company="company"
            :key="index"
            @remove_company="remove_company"
            v-for="(company, index) in current_page_companies"
            v-if="isActive"
          />
          <!-- Archived Companies Component -->
          <ArchivedCompany
            :company="archive_company"
            :key="index"
            v-for="(archive_company, index) in current_archived_page_companies"
            v-if="!isActive"
          />
        </v-row>
      </v-app>

      <!-- Import Comonents -->
      <add-company-modal />
      <EditCompanyModal />

      <!-- Remove Company Modal -->
      <div class="text-center">
        <v-dialog
          v-model="deleteDialog"
          width="500"
          transition="dialog-top-transition"
        >
          <v-card>
            <v-card-title class="headline red lighten-2">
              Are you sure?
            </v-card-title>
            <v-card-text class="mt-4">
              <h4>
                If you archive the company, the subscription for the associated
                employees will be cancelled
              </h4>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                text
                @click="deleteDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="error"
                v-if="removeCompanyUserStatus == 'employer'"
                outlined
                text
                @click="
                  (deleteDialog = false), deleteOrArchiveCompany('delete')
                "
              >
                Delete
              </v-btn>
              <v-btn
                color="warning"
                outlined
                text
                @click="
                  (deleteDialog = false), deleteOrArchiveCompany('archive')
                "
              >
                Archive
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "companies-listing",
  components: {
    ActiveCompanies: () => import("@/components/companies/ActiveCompany"),
    ArchivedCompany: () => import("@/components/companies/ArchivedCompany"),
    AddCompanyModal: () => import("@/components/companies/AddCompanyModal"),
    EditCompanyModal: () => import("@/components/companies/EditCompanyModal"),
    NoCompanyFound: () => import("@/components/includes/errors/NoCompanyFound"),
  },
  data() {
    return {
      currentPage: 1,
      archive_currentPage: 1,
      search: "",
      arch_search: "",
      editCompany: "",
      isActive: true,
      isShow: true,
      deleteDialog: false,
      result: "",
      removeCompanyData: "",
      removeCompanyUserStatus: "",
    };
  },
  computed: {
    ...mapState("custom", [
      "selected_company",
      "all_companies_list",
      "projects_list",
      "companies_list",
    ]),
    /**
     * Return the lenght of active companies
     */
    companiesLength() {
      return this.$store.state.custom.all_companies_list.active.length;
    },
    /**
     * Return the list of active companies
     */
    active_companies() {
      return this.all_companies_list ? this.all_companies_list.active : [];
    },
    /**
     * Return the list of archived companies
     */
    archived_companies() {
      return this.all_companies_list
        ? this.all_companies_list.archived.filter(
            (company) => company.user_status !== "employee"
          )
        : [];
    },
    show_active_tabs() {
      let allow = false;
      this.active_companies.forEach((element) => {
        if (element.user_status !== "employee") {
          allow = true;
        }
      });
      this.archived_companies.forEach((element) => {
        if (element.user_status !== "employee") {
          allow = true;
        }
      });
      return allow;
    },
    /**
     * Return the current page of active companies
     */
    current_page_companies() {
      this.search = this.search.trimStart(); //Does not allow initial whitespaces.
      return this.active_companies.filter((company) =>
        company.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    /**
     * Return the current page of archived companies
     */
    current_archived_page_companies() {
      return this.archived_companies.filter((company) =>
        company.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    /**
     * Return the list of active companies
     */
    active_projects() {
      return this.projects_list.active;
    },
  },
  mounted() {
    this.$store.dispatch("custom/getAllCompaniesList");
    const notifications_page = this.$store.state.auth.showTutorialNotifications;
    notifications_page.map((notifications_page) => {
      if (
        notifications_page.page_name === "companies_listing" &&
        notifications_page.is_hide == true
      ) {
        return (this.isShow = false);
      }
    });
  },
  methods: {
    /**
     * This function is resposible for sending
     * custom emit to add company component
     * to trigger modal
     */
    addCompany() {
      this.$root.$emit("add_company_data");
    },
    /**
     * This function is resposible for removing
     * company and selected the value
     */
    remove_company(value) {
      this.deleteDialog = true;
      this.removeCompanyData = value.id;
      this.removeCompanyUserStatus = value.user_status;
    },
    /**
     * This function is resposible for removing
     * or archive company from company listing
     */
    deleteOrArchiveCompany(result) {
      let value = this.removeCompanyData;
      let that = this;
      this.active_companies.forEach((element, index) => {
        if (element.id === value) {
          if (result) {
            if (result === "delete") {
              that.$store
                .dispatch("custom/delete_company", { company_id: value })
                .then((response) => {
                  this.$store.commit("custom/toggle_loader", false);
                  if (response.data.error === false) {
                    if (this.$route.params.id === value) {
                      this.$store.commit("custom/set_selected_company", "");
                    }
                    this.$store.dispatch("custom/getAllCompaniesList");
                    that.$store.commit("custom/splice_company", index);
                    this.$root.$emit("snack_bar", {
                      show: true,
                      message:
                        "We have send you a confirmation email, please check your inbox.",
                      snackbarColor: "green",
                    });
                  }
                })
                .catch(() => {
                  this.$store.commit("custom/toggle_loader", false);
                });
            } else if (result === "archive") {
              that.$store
                .dispatch("custom/archive_company", { company_id: value })
                .then((response) => {
                  this.$store.commit("custom/toggle_loader", false);
                  if (response.data.error === false) {
                    if (this.$route.params.id === value) {
                      this.$store.commit("custom/set_selected_company", "");
                    }
                    this.$store.dispatch("custom/getAllCompaniesList");
                    that.$store.commit("custom/splice_company", index);
                    that.$store.commit("custom/push_archive_company", element);
                    this.$root.$emit("snack_bar", {
                      show: true,
                      message: "Your record is archived successfully!",
                      snackbarColor: "green",
                    });
                  }
                })
                .catch(() => {
                  this.$store.commit("custom/toggle_loader", false);
                });
            }
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}
.inactive-custom-btn {
  border: 1px solid #2758f6 !important;
  border-radius: 3px !important;
  background-color: #fff !important;
  color: #2758f6 !important;
}
</style>
