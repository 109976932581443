<!-- System: STA
    Purpose: In Projects user can see his all active and 
          archived projects, create new project, update
          project, archived and retore project.
-->
<template>
  <v-app>
    <v-row class="mt-2">
      <!-- Active & Archived Tabs/Buttons  -->
      <v-col cols="12" sm="6" md="4">
        <v-btn
          dark
          elevation="5"
          :class="{
            'active-custom-btn': isActive,
            'inactive-custom-btn': !isActive,
          }"
          class="mt-3"
          @click="isActive = true"
        >
          Active
        </v-btn>
        <v-btn
          dark
          elevation="5"
          :class="{
            'active-custom-btn': !isActive,
            'inactive-custom-btn': isActive,
          }"
          @click="isActive = false"
          class="ml-2 mt-3"
        >
          Archived
        </v-btn>
      </v-col>
      <!-- Search Bar For Projects  -->
      <v-col
        cols="12"
        sm="6"
        :offset-md="
          selectedCompanyUserStatus !== 'employee' && showPermission ? 2 : 4
        "
        md="3"
        class="px-5 mt-3"
      >
        <v-text-field
          id="search"
          v-model="search"
          autocomplete="off"
          ref="search"
          color="primary"
          outlined
          dense
          label="Search in Projects"
          v-show="
            projects_list.archived.length > 10 ||
            projects_list.active.length > 10
          "
        ></v-text-field>
      </v-col>
      <!-- Create New Project Component  -->
      <v-col
        cols="12"
        sm="6"
        md="2"
        v-if="selectedCompanyUserStatus !== 'employee' && showPermission"
      >
        <CreateProject />
      </v-col>
    </v-row>

    <!-- Active Project Components  -->
    <v-container v-if="isActive" :fluid="true" class="px-sm-15">
      <v-app>
        <v-row v-if="current_page_projects.length > 0" class="px-sm-15">
          <v-col
            lg="3"
            md="4"
            sm="6"
            xl="2"
            :key="project.id"
            class="mt-4"
            v-for="project in current_page_projects"
          >
            <ActiveProjects :key="project.id" :project="project" />
          </v-col>
        </v-row>
        <v-row v-else class="d-flex justify-center">
          <NoProjectFound />
        </v-row>
      </v-app>
    </v-container>

    <!-- Archived Project Components -->
    <v-container v-if="!isActive" :fluid="true" class="px-sm-15">
      <v-app>
        <v-row
          v-if="archived_current_page_projects.length > 0"
          class="px-sm-15"
        >
          <v-col
            :key="index"
            v-for="(arch_project, index) in archived_current_page_projects"
            lg="3"
            md="4"
            sm="6"
            xl="2"
            class="float-left mt-4 archived-projects"
          >
            <ArchivedProjects :key="index" :project="arch_project" />
          </v-col>
        </v-row>
        <v-row v-else class="d-flex justify-center">
          <NoProjectFound />
        </v-row>
      </v-app>
    </v-container>

    <!-- Archive Project Modal -->
    <v-dialog
      v-model="archiveProjectDialog"
      width="500"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar color="primary" class="headline" dark
          >Are you sure?</v-toolbar
        >
        <v-card-text class="mt-4">
          <h4>
            If you archive this project, status will be changed to closed and
            inactive. Associated members not involved in any other project will
            be archived too. You can restore all archived data later.
          </h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="archiveProjectDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            outlined
            text
            @click="(archiveProjectDialog = false), archiveProject()"
          >
            <v-progress-circular indeterminate v-if="archiveLoader" size="20">
            </v-progress-circular>
            Archive
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Restore Project Modal  -->
    <v-dialog
      v-model="restoreProjectDialog"
      width="500"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar color="primary" class="headline" dark
          >Are you sure?</v-toolbar
        >
        <v-card-text class="mt-4">
          <h4>Are you sure you want to restore the project?</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            text
            @click="restoreProjectDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green"
            outlined
            text
            @click="(restoreProjectDialog = false), restoreProject()"
          >
            <v-progress-circular indeterminate v-if="restoreLoader" size="20">
            </v-progress-circular>
            Restore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Update Project Modal Component -->
    <UpdateProject />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "projects",
  components: {
    ActiveProjects: () => import("@/components/projects/ActiveProjects"),
    ArchivedProjects: () => import("@/components/projects/ArchivedProjects"),
    CreateProject: () => import("@/components/projects/CreateProject"),
    UpdateProject: () => import("@/components/projects/UpdateProject"),
    NoProjectFound: () => import("@/components/includes/errors/NoProjectFound"),
  },
  data() {
    return {
      isActive: true,
      archiveLoader: false,
      restoreLoader: false,
      search: "",
      archiveProjectDialog: false,
      restoreProjectDialog: false,
      projectToArchive: "",
      projectToRestore: "",
    };
  },

  computed: {
    ...mapState("custom", [
      "projects_list",
      "selected_company",
      "companies_list",
    ]),
    ...mapState("auth", ["user"]),
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.project) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    /**
     * Return all active project
     */
    active_projects() {
      return this.projects_list.active;
    },
    /**
     * Return all archived project
     */
    archived_projects() {
      if (this.projects_list.archived.length == 0) {
        this.isActive = true;
      }
      return this.projects_list.archived;
    },
    /**
     * Return searched active projects
     * when search project from search bar
     */
    current_page_projects() {
      return this.projects_list.active.filter((project) =>
        project.project_name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    /**
     * Return searched archived projects
     * when search project from search bar
     */
    archived_current_page_projects() {
      return this.projects_list.archived.filter((project) =>
        project.project_name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  mounted() {
    if (this.projects_list.active.length == 0) {
      this.$store.commit("custom/toggle_loader", true);
    }
    this.getProjects();
    if (this.selectedCompanyUserStatus !== "employee") {
      this.getEmployeeEmployers();
    }
    /**
     * Custom event to trigger archive
     * project modal
     */
    this.$root.$on("archive-project", (project) => {
      this.archiveProjectDialog = true;
      this.projectToArchive = project;
    });
    /**
     * Custom event to trigger restore
     * project modal
     */
    this.$root.$on("restore-project", (project) => {
      this.restoreProjectDialog = true;
      this.projectToRestore = project;
    });
  },
  methods: {
    /**
     * This function is resposible for fetching
     * project list
     */
    getProjects() {
      this.$store.dispatch("custom/getProjects", {
        company_id: this.$route.params.id,
      });
    },
    /**
     * This function is resposible for fetching
     * all employees of employers
     */
    getEmployeeEmployers() {
      let company_id = this.$route.params.id;
      this.$store.dispatch("custom/getEmployeeEmployers", { company_id });
    },
    /**
     * This function is resposible for archive
     * project from active project list
     */
    archiveProject() {
      let project = this.projectToArchive;
      let company_id = this.$route.params.id;
      let delProject = { company_id: company_id, project_id: project.id };
      this.archiveLoader = true;
      this.$store
        .dispatch("custom/archivedProject", delProject)
        .then((response) => {
          this.archiveLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false)
            this.$store.commit("custom/splice_project", project.id);
          this.$store.commit("custom/push_archive_project", project);
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Your project is archived successfully!",
            snackbarColor: "green",
          });
          this.projectToArchive = "";
        })
        .catch((error) => {
          this.archiveLoader = true;
          if (error.response.data.error === true) {
            this.$store.commit("custom/toggle_loader", false);
            setTimeout(() => {
              this.$root.$emit("snack_bar", {
                show: true,
                message: Object.values(error.response.data.errors)[0][0],
                snackbarColor: "red",
              });
            }, 500);
          }
        });
    },
    /**
     * This function is resposible for restore
     * project from archived project list
     */
    restoreProject() {
      let project = this.projectToRestore;
      let company_id = this.$route.params.id;
      this.restoreLoader = true;
      this.$store
        .dispatch("custom/restore_project", {
          company_id: company_id,
          project_id: project.id,
        })
        .then((response) => {
          this.restoreLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.$store.commit("custom/splice_archived_project", project.id);
            this.$store.commit("custom/push_project", project);
            this.$root.$emit("snack_bar", {
              show: true,
              message: "Your project is restore successfully!",
              snackbarColor: "green",
            });
            this.projectToRestore = "";
          }
        })
        .catch((error) => {
          this.restoreLoader = false;
          if (error.response.data.error === true) {
            this.$store.commit("custom/toggle_loader", false);
            setTimeout(() => {
              this.$root.$emit("snack_bar", {
                show: true,
                message: Object.values(error.response.data.errors)[0][0],
                snackbarColor: "red",
              });
            }, 500);
          }
        });
    },
  },
};
</script>

<style scoped>
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}
.inactive-custom-btn {
  border: 1px solid #2758f6 !important;
  border-radius: 3px !important;
  background-color: #fff !important;
  color: #2758f6 !important;
}
</style>
