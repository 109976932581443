<template>
  <h3 class="text-center redirect-text mt-5">Redirecting...</h3>
</template>

<script>
export default {
  name: "projectInvitationLink",
  mounted() {
    this.redirect();
  },
  methods: {
    redirect() {
      this.$store
        .dispatch("auth/project_invitation_link", {
          company_id: this.$route.params.company_id,
          project_id: this.$route.params.project_id,
          user_id: this.$route.params.user_id,
          email: this.$route.params.email,
        })
        .then((response) => {
          this.$root.$emit("snack_bar", {
            show: true,
            message: "You have successfully accepted the invitation",
            snackbarColor: "green",
          });
          return this.$router.push({ name: "login" });
          // this.$router.push ( '/user-login/' + response.data.data.encrypted_id + '/' + this.$route.params.company_id );
        })
        .catch((error) => {
          if (error.response.data.error === true) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
            return this.$router.push({ name: "login" });
          }
        });
    },
  },
};
</script>

<style scoped></style>
