import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import CompanyListing from "../views/CompanyListing";
import Projects from "../views/Projects";
import auth from "../../src/components/middleware/auth";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      meta: { title: "Login", layout: "simple" },
      component: Login,
    },
    {
      path: "/set/password/:hash_id",
      name: "update_password",
      meta: { title: "Password", layout: "simple" },
      component: require("@/views/NewCompanyPassword").default,
    },
    {
      path: "/invite/:invite_token",
      name: "invite-link",
      meta: { title: "Invitation", layout: "simple" },
      component: require("@/views/InviteLink").default,
    },
    {
      path: "/password/reset/:token/:notification_id",
      name: "reset-password",
      meta: { title: "Reset Password", layout: "simple" },
      component: require("@/views/ResetPassword").default,
    },
    {
      path: "/invitation/accept/:token/:email/:notification_id",
      name: "set-password",
      meta: { title: "Accept Invitation", layout: "simple" },
      component: require("@/views/EmailInviteMemberSetPassword").default,
    },
    {
      path:
        "/project/invitation/accept/:company_id/:project_id/:user_id/:email/:notification_id",
      name: "project-invitation-link",
      meta: { title: "Accept Project Invitation", layout: "simple" },
      component: require("@/views/ProjectInvitationLink").default,
    },
    {
      path: "/verify/email/:token/:notification_id",
      name: "verify_email",
      meta: { title: "Verify Email", layout: "simple" },
      component: require("@/views/verifyEmail").default,
    },
    {
      path: "/user-login/:token/:company_id?",
      name: "user-login",
      meta: { title: "Login", layout: "simple" },
      component: require("@/views/UserLogin").default,
    },
    {
      path: "/resend_email",
      name: "resend_email",
      meta: { title: "Resend Email", layout: "simple" },
      component: Login,
    },
    {
      path: "/companies",
      name: "CompaniesListing",
      meta: {
        title: "Company Listing",
        requiresAuth: true,
        layout: "app",
      },
      component: CompanyListing,
    },
    {
      path: "/account-settings",
      name: "AccountSettingsRoot",
      component: () => import("../../src/views/AccountSettings"),
      meta: {
        title: "Account Settings",
        layout: "app",
        requiresAuth: true,
      },
    },
    {
      path: "/company/delete/twofactor/:factor/:notification_id",
      name: "company-deletion",
      meta: { title: "Company Deletion", layout: "simple" },
      component: require("@/views/CompanyDeletion").default,
    },
    {
      path: "/:id",
      component: require("../../src/components/layouts/CompanyLayout").default,
      children: [
        {
          path: "",
          redirect: "dashboard",
        },
        {
          path: "account-settings",
          name: "AccountSettings",
          component: () => import("../../src/views/AccountSettings"),
          meta: {
            title: "Account Settings",
            requiresAuth: true,
          },
        },
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("../../src/views/Dashboard"),
          meta: {
            title: "Dashboard",
            requiresAuth: true,
          },
        },
        {
          path: "projects",
          name: "Projects",
          meta: {
            title: "Projects",
            requiresAuth: true,
          },
          component: Projects,
        },
        {
          path: "team-members/:new?",
          name: "TeamMembers",
          component: () => import("../views/TeamMembers"),
          meta: {
            title: "Team Members",
            requiresAuth: true,
          },
        },
        {
          path: "tasks",
          name: "Task",
          component: () => import("../views/Tasks"),
          meta: {
            title: "Tasks",
            requiresAuth: true,
          },
        },
        {
          path: "work-diary/:member_id?/:date?",
          name: "WorkDiary",
          component: () => import("@/views/WorkDiary"),
          meta: {
            title: "Work Diary",
            requiresAuth: true,
          },
        },
        {
          path: "all-team-leads",
          name: "AllTeamLeads",
          component: () => import("@/views/AllTeamLeads"),
          meta: {
            title: "All Team Leads",
            requiresAuth: true,
          },
        },
        {
          path: "billing",
          name: "Billing",
          component: () => import("@/views/Billing"),
          meta: {
            title: "Billing",
            requiresAuth: true,
          },
        },
        {
          path: "reports",
          name: "Reports",
          component: () => import("../views/reports"),
          meta: {
            title: "Reports",
            requiresAuth: true,
          },
        },
        {
          path: "members-group-monitor",
          name: "members-group-monitor",
          component: () =>
            import("../components/TeamMembers/RealTimeMonitor/RealTimeMonitor"),
          meta: {
            title: "Group Monitoring",
            requiresAuth: true,
          },
        },
        {
          path: "download-app/:first?",
          name: "DownloadApp",
          component: () => import("../views/DownloadAppBackoffice"),
          meta: {
            title: "Download App",
            layout: "app",
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});
router.beforeEach(async (to, from, next) => {
  // Set Page's Title
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle)
    document.title = `${nearestWithTitle.meta.title} | Staff Timer App`;
  // Check for route access permission
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!(await auth.user())) {
      next({
        name: "login",
      });
    } else if (await auth.check_status()) {
      switch (to.name) {
        case "TeamMembers":
        case "Billing":
          next({
            path: "/pagenotfound",
          });
          break;
        default:
          next();
          break;
      }
    } else if (await auth.check_admin()) {
      switch (to.name) {
        case "members-group-monitor":
          (await auth.check_permission(to.name))
            ? next()
            : next({
              path: "/pagenotfound",
            });
          break;
        case "TeamMembers":
          (await auth.check_permission(to.name))
            ? next()
            : next({
              path: "/pagenotfound",
            });
          break;
        case "Billing":
          (await auth.check_permission(to.name))
            ? next()
            : next({
              path: "/pagenotfound",
            });
          break;
        default:
          next();
          break;
      }
    } else if (await auth.check_partner_id()) {
      if (to.name === "Billing") {
        next({
          path: "/pagenotfound",
        });
      } else {
        next();
      }
    } else if (
      ((await auth.check_company_exist()) === false ||
        (await auth.check_selected_company(to, from)) === false) &&
      ![
        "CompaniesListing",
        "AddCompany",
        "AccountSettingsRoot",
        "sync-project",
        "DownloadApp",
      ].includes(to.name)
    ) {
      if ((await auth.check_company_exist()) === false) {
        next({
          name: "AddCompany",
        });
      } else if ((await auth.check_selected_company(to, from)) === false) {
        notify(
          "Warning!",
          "Please select a company first to get started.",
          "warning"
        );
        next({
          name: "CompaniesListing",
        });
      }
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});
export default router;
