import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//===== Load module JS from node modules
import Vuetify from "vuetify";
import createPersistedState from 'vuex-persistedstate'
import VueSocketio from 'vue-socket.io-extended'

//===== Load Utilities
import { confirm_dialog, disabled_btn, notify } from "./assets/js/utils";
import { baseURL } from "./assets/js/urls";
import socket from "./assets/js/socket";
//===== Load Css from node modules
import "vuetify/dist/vuetify.min.css"
import '@mdi/font/css/materialdesignicons.css'

createPersistedState()(store);

Vue.use(VueSocketio, socket, { store });

Vue.config.productionTip = true;

Vue.use(Vuetify);

Vue.notify = Vue.prototype.$notify = notify;
Vue.confirm_dialog = Vue.prototype.$confirm_dialog = confirm_dialog;
Vue.api_url = Vue.prototype.$api_url = baseURL.API_URL;
Vue.disabled_btn = Vue.prototype.$disabled_btn = disabled_btn;
Vue.prototype.$echo = { Echo: null };
Vue.component('v-style', {
    render: function (createElement) {
        return createElement('style', this.$slots.default)
    }
});
Vue.prototype.$eventBus = new Vue();
store.$vue = new Vue({
    router,
    store,
    vuetify: new Vuetify(),
    render: h => h(App)
}).$mount('#app');
