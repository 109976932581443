<template>
  <h3 class="text-center redirect-text mt-5">Redirecting...</h3>
</template>

<script>
import Cookie from "js-cookie";
export default {
  name: "user-login",
  data() {
    return {
      img_url: "",
      base64: "",
      error: "",
    };
  },
  mounted() {
    document.cookie = "XSRF-TOKEN= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    Cookie.remove("XSRF-TOKEN", {
      path: "/",
      domain: ".stafftimerapp.com",
      sameSite: "lax",
    });
    Cookie.remove("stafftimerapp_session", {
      path: "/",
      domain: ".stafftimerapp.com",
      sameSite: "lax",
    });

    if (this.$store.state.auth.user !== null) {
      this.$store
        .dispatch("custom/user_logout")
        .then(() => {
          this.$store.commit("custom/resetState");
          this.$store.dispatch("auth/reset").then(() => {
            this.redirect();
          });
        })
        .catch(() => {
          this.$store.commit("custom/resetState");
          this.$store.dispatch("auth/reset").then(() => {
            this.redirect();
          });
        });
    } else {
      this.redirect();
    }
  },
  methods: {
    redirect() {
      this.$store
        .dispatch("auth/app_login", { encrypted_id: this.$route.params.token })
        .then((response) => {
          let company_id = this.$route.params.company_id;

          this.$store.dispatch("auth/setCurrentUser", response.data);

          this.$store.dispatch("custom/getAllCompaniesList").then(async () => {
            this.$store.commit("auth/broadcast_to_other_tabs_to_reload");
            this.$store.commit("pf/keysUpdate");
            this.$store.commit("custom/USER_LOGIN", true);
            if (company_id) {
              let company = this.$store.state.custom.companies_list.find(
                (company) => company.id === company_id
              );
              if (!company) {
                this.$root.$emit("snack_bar", {
                  show: true,
                  message: "Unknown company",
                  snackbarColor: "red",
                });
                this.$store.commit("custom/set_selected_company", "");
                return this.$router.push({ name: "CompaniesListing" });
              }
              this.$store.commit("custom/set_selected_company", company);
              this.$nextTick(() =>
                setTimeout(
                  () =>
                    this.$router.push({
                      name: "Projects",
                      params: { id: company.id },
                    }),
                  1000
                )
              );
            } else {
              this.$store.commit(
                "custom/set_selected_company",
                this.$store.state.custom.companies_list[0]
              );
            }

            let selected_company = this.$store.state.custom.selected_company;
            if (selected_company && selected_company.id) {
              await this.$router.push({
                name: "Dashboard",
                params: { id: selected_company.id },
              });
            } else await this.$router.push({ name: "CompaniesListing" });
          });
        })
        .catch((error) => {
          if (error.response.data.error === true) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: error.response.data.error_msg,
              snackbarColor: "red",
            });
          }
        });
    },
  },
};
</script>

<style scoped></style>
